import { FC } from 'react'

import Stack, { StackProps } from '@mui/material/Stack'
import cn from 'classnames'

import { PairStats } from '@/components/pair-stats'
import { Tabs } from '@/libs/common/index'
// import { TabName } from '@/libs/enums'
import { TTab } from '@/libs/types'

import { OrderHistoryTab } from './libs/components'
// import { SnipeTab } from './libs/components/snipe-tab'
import styles from './styles.module.scss'

const PendingOrders: FC<StackProps> = ({ className, ...props }) => {
  const tabs: TTab[] = [
    { label: 'Volume', content: <PairStats variant="pro" /> },
    {
      label: 'Order History',
      content: <OrderHistoryTab />,
    },
  ]

  return (
    <Stack className={cn(styles.container, className)} spacing={2} useFlexGap {...props}>
      <Tabs tabs={tabs} defaultValue={0} />
    </Stack>
  )
}

export { PendingOrders }
