import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { EBuyMode } from '@/libs/enums/buy-mode.enum'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { getPrioritiesDefault } from '@/libs/helper/getPrioritiesDefault'
import { stringsToNumbersInObject } from '@/libs/helper/stringsToNumbersInObject'
import { TBuyTransaction } from '@/libs/types/buy-transaction'
import { TManualBuyForm } from '@/libs/types/manual-buy-form.type'
import { store } from '@/store'

type TWallet = {
  name: string
  public_key: string
}

type TProps = {
  data: TManualBuyForm | any
  wallets: TWallet[]
  isTemplate?: boolean
  percentMode?: boolean
  degenMode?: boolean
}

const defaultShields = {
  minimum_liquidity: 0,
  maximum_liquidity: 0,
  maximum_market_cap: 0,
  buy_tax: EBuyMode.MAX_PERCENT,
  anti_market_cap: false,
}

const createManualBuyPayload = ({
  data,
  wallets,
  isTemplate = false,
  percentMode = false,
  degenMode = false,
}: TProps) => {
  const defaultPriorities = getPrioritiesDefault()
  const userData = store.getState().user.userData
  const currentToken = store.getState().chain.currentToken
  const currentChain = store.getState().chain.currentChain
  const currentDex =
    store.getState().chain.adaptedCurrentTokenSimulationWebsocket?.data.liquidity.suggested_dex

  if (!userData) return

  const shields = { ...defaultShields, ...stringsToNumbersInObject(data.shields, 0) }
  const buyPriority = currentChain.features?.buyPriority
    ? +data.advancedBuy.buyPriority || +defaultPriorities.buyPriority
    : 0

  let buy_mode = EBuyMode.CHAIN_CURRENCY
  if (data.advancedBuy.maxTxOrFail) {
    buy_mode = EBuyMode.MAX_TRANSACTION
  } else if (data.advancedBuy.minPercentTokenOrFail) {
    buy_mode = percentMode ? EBuyMode.MIN_PERCENT : EBuyMode.MIN_TOKENS
  }

  const bribeAmount = currentChain.features?.bribeAmount
    ? +data.advancedBuy.bribeAmount || +defaultPriorities.bribeAmount
    : 0

  if (!!currentChain.features?.degenMode && degenMode) {
    shields.minimum_liquidity = 0
    shields.maximum_liquidity = EBuyMode.MAX_LIQUIDITY
    shields.maximum_market_cap = EBuyMode.MAX_MARKET_CAP
    shields.buy_tax = EBuyMode.MAX_PERCENT
    shields.anti_market_cap = false
  }

  const payload = {
    token: isTemplate || !currentToken ? '' : currentToken.token.address, // Token address a user wants to buy
    network: {
      blockchain: currentChain.indexerChainId, // Current blockchain id
    },
    wallets,
    operation: {
      private_tx: !currentChain.features?.noPrivateTx && data.ordinaryBuy.privateTransaction,
      tx_type: currentChain.defaultValues?.txType || 0,
      bribe_amount: bribeAmount,
      contract: {
        separate_txs: true, // Combined transactions are not working so far, so we use separate ones
      },
      pair: {
        routing: {
          mode: 2, // 0 for chain's native tokens; 0 - base mode 2 - auto mode
          path: [], // Not specified, as we only use native tokens so far
        },
        fee: {
          mode: 1, // 1 for the automatic calculation
          path_fees: [], // This field is not used when mode = 1, but the Sniping requires it anyway
        },
      },
      setup: {
        buy_mode,
        buy_amount: isTemplate
          ? +data.ordinaryBuy.buy_amount
          : +convertScientificNotationNumber(+data.ordinaryBuy.spend, MAX_TRX_DECIMALS) || 0, // Amount of tokens a user wants to exchange
        // Amount of tokens a user wants to buy
        buy_tokens:
          buy_mode === EBuyMode.MIN_TOKENS
            ? +data.advancedBuy.minPercentTokenOrFail.replace(/%/g, '') || 0
            : +data.ordinaryBuy.receive || 0,
        supply_percentage:
          buy_mode === EBuyMode.MIN_PERCENT
            ? +data.advancedBuy.minPercentTokenOrFail.replace(/%/g, '')
            : 0,
        rounds_to_buy: 1, // Default value
        slippage: +data.ordinaryBuy.slippage,
        auto_approve: true, // If true, then no need to call an approve endpoint after the buying
        force_buy: true, // Default value
      },
      shields: {
        anti_fake_liquidity:
          !data.ordinaryBuy.degenChadMode &&
          (!!shields.minimum_liquidity || !!shields.maximum_liquidity),
        anti_tax: !data.ordinaryBuy.degenChadMode && !!(shields.buy_tax || shields.sell_tax),
        anti_honeypot: !data.ordinaryBuy.degenChadMode,
        anti_market_cap: degenMode ? shields.anti_market_cap : !!shields.maximum_market_cap,
        anti_duplicate: !!shields.anti_duplicate,
        buy_tax: shields.sell_tax && !shields.buy_tax ? 100 : shields.buy_tax,
        sell_tax: shields.buy_tax && !shields.sell_tax ? 100 : shields.sell_tax,
        maximum_liquidity:
          shields.minimum_liquidity && !shields.maximum_liquidity
            ? 1000000000
            : shields.maximum_liquidity,
        minimum_liquidity: shields.minimum_liquidity,
        maximum_market_cap: shields.maximum_market_cap,
        // Default values
        minimum_market_cap: 0,
        anti_honeypot_mode: 0,
        anti_fake_liquidity_mode: 0,
        anti_tax_mode: 0,
        anti_price_impact: false,
        price_impact: 0,
        anti_buy_sell_ratio: false,
        buy_sell_ratio: 0,
        buy_simulation: false,
        buy_simulation_mode: 0,
        anti_freeze_mode: '0',
        anti_freeze: true,
      },
    },
  } as TBuyTransaction

  if (currentDex !== null) {
    payload.network.dex = isTemplate || !currentDex ? null : +currentDex // Selected dex service
  }

  if (currentChain.features?.usePriorityAmount) {
    payload.operation.priority_amount = buyPriority
  }

  if (currentChain.features?.useGasInTx) {
    const approvePriority = currentChain.features?.approvePriority
      ? +data.advancedBuy.approvePriority || +defaultPriorities.approvePriority
      : 0

    payload.operation.gas = {
      approve: {
        gas: currentChain.features?.noPriorityGas ? approvePriority : 10,
        miner_tip: currentChain.features?.noPriorityGas ? 0 : approvePriority, // 7 is the default value
        auto_calculate: true,
        gas_limit: 1000000,
      },
      buy: {
        gas: currentChain.features?.noPriorityGas ? buyPriority : 10,
        miner_tip: currentChain.features?.noPriorityGas ? 0 : buyPriority, // 7 is the default value
        auto_calculate: true,
        gas_limit: 1000000,
      },
    }
  }

  return payload

  // TODO: Check this implementation for Solana
  // return {
  //   token: isTemplate || !currentToken ? '' : currentToken.token.address, // Token address a user wants to buy
  //   network: {
  //     blockchain: currentChain.indexerChainId, // Current blockchain id
  //     dex: isTemplate || !currentDex ? null : +currentDex, // Selected dex service
  //   },
  //   wallets,
  //   operation: {
  //     private_tx: !currentChain.features?.noPrivateTx && data.ordinaryBuy.privateTransaction,
  //     tx_type: currentChain.defaultValues?.txType || 0,
  //     bribe_amount: bribeAmount,
  //     contract: {
  //       separate_txs: true, // Combined transactions are not working so far, so we use separate ones
  //     },
  //     gas: !!currentChain.features?.useGasInTx
  //       ? {
  //           approve: {
  //             gas: currentChain.features?.noPriorityGas ? approvePriority : 10,
  //             miner_tip: currentChain.features?.noPriorityGas ? 0 : approvePriority, // 7 is the default value
  //             auto_calculate: true,
  //             gas_limit: 1000000,
  //           },
  //           buy: {
  //             gas: currentChain.features?.noPriorityGas ? buyPriority : 10,
  //             miner_tip: currentChain.features?.noPriorityGas ? 0 : buyPriority, // 7 is the default value
  //             auto_calculate: true,
  //             gas_limit: 1000000,
  //           },
  //         }
  //       : null,
  //     pair: {
  //       routing: {
  //         mode: 2, // 0 for chain's native tokens; 0 - base mode 2 - auto mode
  //         path: [], // Not specified, as we only use native tokens so far
  //       },
  //       fee: {
  //         mode: 1, // 1 for the automatic calculation
  //         path_fees: [], // This field is not used when mode = 1, but the Sniping requires it anyway
  //       },
  //     },
  //     setup: {
  //       buy_mode,
  //       buy_amount: isTemplate
  //         ? +data.ordinaryBuy.buy_amount
  //         : +convertScientificNotationNumber(+data.ordinaryBuy.spend, MAX_TRX_DECIMALS) || 0, // Amount of tokens a user wants to exchange
  //       // Amount of tokens a user wants to buy
  //       buy_tokens:
  //         buy_mode === EBuyMode.MIN_TOKENS
  //           ? +data.advancedBuy.minPercentTokenOrFail.replace(/%/g, '') || 0
  //           : +data.ordinaryBuy.receive || 0,
  //       supply_percentage:
  //         buy_mode === EBuyMode.MIN_PERCENT
  //           ? +data.advancedBuy.minPercentTokenOrFail.replace(/%/g, '')
  //           : 0,
  //       rounds_to_buy: 1, // Default value
  //       slippage: +data.ordinaryBuy.slippage,
  //       auto_approve: true, // If true, then no need to call an approve endpoint after the buying
  //       force_buy: true, // Default value
  //     },
  //     shields: {
  //       anti_fake_liquidity:
  //         !data.ordinaryBuy.degenChadMode &&
  //         (!!shields.minimum_liquidity || !!shields.maximum_liquidity),
  //       anti_tax: !data.ordinaryBuy.degenChadMode && !!(shields.buy_tax || shields.sell_tax),
  //       anti_honeypot: !data.ordinaryBuy.degenChadMode,
  //       anti_market_cap: degenMode ? shields.anti_market_cap : !!shields.maximum_market_cap,
  //       anti_duplicate: !!shields.anti_duplicate,
  //       buy_tax: shields.sell_tax && !shields.buy_tax ? 100 : shields.buy_tax,
  //       sell_tax: shields.buy_tax && !shields.sell_tax ? 100 : shields.sell_tax,
  //       maximum_liquidity:
  //         shields.minimum_liquidity && !shields.maximum_liquidity
  //           ? 1000000000
  //           : shields.maximum_liquidity,
  //       minimum_liquidity: shields.minimum_liquidity,
  //       maximum_market_cap: shields.maximum_market_cap,
  //       // Default values
  //       minimum_market_cap: 0,
  //       anti_honeypot_mode: 0,
  //       anti_fake_liquidity_mode: 0,
  //       anti_tax_mode: 0,
  //       anti_price_impact: false,
  //       price_impact: 0,
  //       anti_buy_sell_ratio: false,
  //       buy_sell_ratio: 0,
  //       buy_simulation: false,
  //       buy_simulation_mode: 0,
  //     },
  //   },
  // } as TBuyTransaction
}

export { createManualBuyPayload }
