import { FC, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import cls from 'classnames'
import * as yup from 'yup'

import styles from '@/components/dashboard-component-lite/libs/buy-sell-block/styles.module.scss'
import { ChainNameToNativeTokenIcon } from '@/components/header/libs/maps/chain-name-to-chain-element.tsx'
import { PriorityCurrency } from '@/components/priority-currency'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import {
  Accordion,
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  ButtonIcon,
  Icon,
  Input,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { chainsConfig } from '@/libs/configs/chains.config'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { createSellPayload, formatNumber, handleSell } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { TSelectOption } from '@/libs/types/select-option.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useAppSelector } from '@/store'

type TProperty = {
  onExpand?: (value: boolean) => void
}

const options: TSelectOption<number>[] = [
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
]

const SellTab: FC<TProperty> = ({ onExpand }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const simulation = useAppSelector(
    (state) => state.chain.adaptedCurrentTokenSimulationWebsocket?.data,
  )
  const mainUserWallet = useAppSelector((state) => state.user.mainWallet)
  const sellPanel = useAppSelector((state) => state.profitSell.profitSell?.sp)
  const userConfirmationRequired = useAppSelector((state) => state.user.userData.sell_confirmation)
  const navigate = useCustomNavigate()

  const [variant, setVariant] = useState('eth')
  const [available, setAvailable] = useState(0)

  const solanaId = chainsConfig.find((chain) => chain.label === 'SOLANA')?.id
  const isSolana = currentChain.id === solanaId

  const mainWallet = useMemo(
    () => sellPanel?.find((panel: any) => panel.wa === mainUserWallet?.address),
    [sellPanel, mainUserWallet],
  )
  const defaultUserPriorities = useAppSelector((state) => state.user.defaultPriorities)

  const [isLoading, setIsLoading] = useState(false)
  const [sellPercentage, setSellPercentage] = useState<number>(0)
  const defaultValues = {
    amount: '',
    slippage: currentChain.defaultValues.slippage,
    sellPriority: defaultUserPriorities?.sell_priority ?? '',
    privateTransaction: !currentChain.features?.noPrivateTx,
    buyPriority: defaultUserPriorities?.buy_priority ?? '',
    bribeAmount: defaultUserPriorities?.bribe_amount ?? '',
  }
  const schema = yup.object({
    amount: stringOfNumbersValidation
      .required()
      .test((value) => (variant === '%' ? +value <= 100 && +value >= 0 : +value <= available)),
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
  })

  useEffect(() => {
    const coeff =
      currentChain.nativeTokenPriceInUsd && simulation
        ? +simulation.price.usd / currentChain.nativeTokenPriceInUsd
        : 0
    const newValue = convertScientificNotationNumber(
      +(mainWallet?.ba || 0) * coeff,
      MAX_TRX_DECIMALS,
    )
    setAvailable(+newValue)
  }, [mainWallet, currentChain, simulation])

  useEffect(() => {
    setValue('amount', '')
    setSellPercentage(0)
  }, [variant])

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!mainWallet) return
    setIsLoading(true)

    const payload = await createSellPayload({
      wallets: [{ name: mainWallet.wn, public_key: mainWallet.wa }],
      sellPercentage,
      data: {
        receive: data.amount,
        sell: '0',
        sellPriority: data.sellPriority,
        bribeAmount: data.bribeAmount,
        slippage: data.slippage,
        privateTransaction: data.privateTransaction,
      },
      mode: sellPercentage ? 0 : 1,
      dex: mainWallet.sd,
    })

    if (payload) {
      const percentage = +data.amount / available
      if (userConfirmationRequired) {
        navigate({
          isDashboard: true,
          path: `${AppRoute.MODAL}/${AppRoute.APPROVE_SELL}`,
          state: {
            payload,
            data: {
              receive: sellPercentage ? available * (sellPercentage / 100) : data.amount,
              sell: sellPercentage
                ? mainWallet.ba * (sellPercentage / 100)
                : mainWallet.ba * (percentage / 100),
            },
          },
        })
      } else {
        await handleSell(payload)
      }
    }

    if (sellPercentage) setSellPercentage(0)
    setIsLoading(false)
  }

  const renderAdvancedSettings = () => (
    <Accordion
      className={`${styles.accordion} ${isSolana ? styles.solanaAccordion : ''}`}
      title={
        <div className={styles.accordionTitle}>
          <ButtonIcon icon={IconName.SETTING_2} type="button" />
          <Typography variant="body2">Advanced Settings</Typography>
        </div>
      }
      withDivider
      mainColorArrow
      onChange={onExpand}
    >
      <Grid container rowGap={2}>
        {!currentChain.features?.noPrivateTx && (
          <Controller
            name="privateTransaction"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Anti-Mev"
                tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                {...field}
              />
            )}
          />
        )}
        <Controller
          name="slippage"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <PercentsInput
              label="Slippage"
              onOptionSelect={(value) => field.onChange(value)}
              tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
              placeholder="X"
              error={!!errors.slippage?.message}
              {...field}
            />
          )}
        />
        {!!currentChain.features?.sellPriority && (
          <Controller
            name="sellPriority"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                className={styles.input}
                label={getChainSensitiveLabel('sellPriority')}
                placeholder="Enter gwei amount"
                isNumeric
                tooltipInfo={`Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ${currentChain.chainSymbol}`}
                endAdornment={<PriorityCurrency />}
                error={!!errors.sellPriority?.message}
                {...field}
              />
            )}
          />
        )}
        {!!currentChain.features?.bribeAmount && (
          <Controller
            name="bribeAmount"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                {...field}
                className={styles.input}
                label={getChainSensitiveLabel('bribeAmount')}
                placeholder="Enter bribe amount"
                isNumeric
                tooltipInfo="An extra fee that you can add to increase the likelihood of your transaction being included in a block."
                endAdornment={<PriorityCurrency />}
                error={!!errors.buyPriority?.message}
                onChange={(e: any) => {
                  const newValue = convertScientificNotationNumber(
                    e.target.value,
                    MAX_TRX_DECIMALS,
                    true,
                  )
                  field.onChange(newValue)
                }}
              />
            )}
          />
        )}
      </Grid>
    </Accordion>
  )

  const renderAmountControls = () => (
    <>
      <div className={styles.worthBlock}>
        <Typography variant="body2">Worth in USD: </Typography>
        {/* <Icon name={IconName.ETH_CURRENCY} /> */}
        <Typography variant="body2" textColor="light-grey">
          ${mainWallet ? formatNumber(mainWallet.w.u).formatted : 0}
        </Typography>
      </div>
      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, value, onChange, ...field } }) => (
          <ButtonGroupRadio
            solid
            exclusive
            className={styles.radioGroup}
            groupClassName={styles.radioGroupContent}
            value={value}
            onChange={(_, value) => {
              if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
              if (variant === '%') {
                setSellPercentage(+value)
                onChange(value)
                return
              }
              const newValue = convertScientificNotationNumber(
                available * (+value / 100),
                MAX_TRX_DECIMALS,
              )
              setSellPercentage(+value)
              onChange(newValue)
            }}
            {...field}
          >
            {options?.map((el, index) => (
              <ButtonGroupRadioButton
                value={el.value}
                name="wallet"
                className={styles.customButton}
                key={index}
              >
                {ChainNameToNativeTokenIcon[currentChain.chainName]}
                <Typography variant="body2" textColor="light-grey">
                  {el.label}
                </Typography>
              </ButtonGroupRadioButton>
            ))}
          </ButtonGroupRadio>
        )}
      />
      <Controller
        name="amount"
        control={control}
        render={({ field: { ref, onChange, ...field } }) => (
          <Input
            label="Amount"
            placeholder="Enter amount to sell"
            isNumeric
            className={styles.input}
            onChange={(e) => {
              if (!currentToken || !currentChain.nativeTokenPriceInUsd) return
              if (variant === '%') {
                setSellPercentage(+e.target.value)
                onChange(e.target.value)
                return
              }
              if (!e.target.value) {
                onChange(e.target.value)
                return
              } else {
                const newValue = convertScientificNotationNumber(
                  e.target.value,
                  MAX_TRX_DECIMALS,
                  true,
                )
                onChange(newValue)
              }

              if (sellPercentage) setSellPercentage(0)
            }}
            error={!!errors.amount?.message}
            {...field}
            endAdornment={
              <ButtonGroupRadio
                exclusive
                className={styles.switch}
                value={variant}
                onChange={(_, newValue) => setVariant(newValue)}
              >
                <ButtonGroupRadioButton
                  value="eth"
                  className={cls(styles.switchButton, variant === 'eth' && styles.active)}
                >
                  <Typography variant="body2" textColor="color-grey-3">
                    {currentChain.chainSymbol}
                  </Typography>
                </ButtonGroupRadioButton>
                <ButtonGroupRadioButton
                  value="%"
                  className={cls(styles.switchButton, variant === '%' && styles.active)}
                >
                  <Typography variant="body2" textColor="color-grey-3">
                    %
                  </Typography>
                </ButtonGroupRadioButton>
              </ButtonGroupRadio>
            }
          />
        )}
      />
      <div className={styles.available}>
        <Typography variant="body2" className={styles.param}>
          Available:
        </Typography>
        <Typography variant="body2" className={styles.value}>
          {(+available || 0).toLocaleString('en-US', {
            maximumFractionDigits: 5,
          })}{' '}
          {currentChain.chainSymbol}
        </Typography>
      </div>
    </>
  )

  const renderChainSpecificLayout = () => {
    if (isSolana) {
      return (
        <>
          {renderAmountControls()}
          {renderAdvancedSettings()}
        </>
      )
    }

    return (
      <>
        {renderAdvancedSettings()}
        {renderAmountControls()}
        {!currentChain.features?.noPriorityGas && (
          <Link
            to={`${AppRoute.DASHBOARD}/${AppMode.LITE}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.SETUP_PRIORITIES}`}
            className={styles.link}
          >
            <Typography variant="body2">
              {getChainSensitiveLabel('setupDefaultPriorities')} <Icon name={IconName.SETTING_2} />
            </Typography>
          </Link>
        )}
        <div className={styles.divider}>
          <Typography variant="body2" className={styles.text}>
            OR
          </Typography>
        </div>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderChainSpecificLayout()}
      <Button type="submit" isLoading={isLoading} checkOnAccountLock>
        Sell
      </Button>
    </form>
  )
}

export { SellTab }
