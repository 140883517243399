import { Controller, useFormContext } from 'react-hook-form'

import { Grid } from '@mui/material'

import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { ChainName, IconName } from '@/libs/enums'
import { TManualBuyValidationSchema } from '@/libs/validations/types'
import { useAppSelector } from '@/store'

import { MANUAL_BUY_CHAIN_SETTINGS } from '../../libs/constants'

const ManualTemplateSafetyTab = ({
  chainName,
  iconName,
  chainSymbol,
}: {
  chainName: ChainName
  iconName: any
  chainSymbol: string
  templateId: string | undefined
}) => {
  //const [isAntiRugSettings, setIsAntiRugSettings] = useState(false)
  const windowDimension = useWindowDimensions()
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext<TManualBuyValidationSchema>()
  const chainSettings = MANUAL_BUY_CHAIN_SETTINGS[chainName]
  const degenChadMode = getValues().template.ordinaryBuy.degenChadMode
  const isResponsive = windowDimension.width <= 630
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  return (
    <Grid container rowGap={2} flexDirection="column">
      {/* {!chainSettings[EManualSettingsSection.SAFETY]!.blackListSettings && (
        <Grid container columnGap={3} flexWrap="nowrap">
          <Controller
            name="template.transferOnBlacklist"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Transfer On Blacklist"
                tooltipInfo="Will transfer tokens to the backup wallet when the developer tries to blacklist your wallet."
                {...field}
              />
            )}
          />
          <Controller
            name="template.antiBlacklist"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Anti-Blacklist"
                tooltipInfo="Sells or transfers (if blacklist transfer is enabled) tokens to your wallet upon a blacklist event."
                {...field}
              />
            )}
          />
        </Grid>
      )}

      {!chainSettings[EManualSettingsSection.SAFETY]!.blackListSettings && (
        <HiddenInputs
          expanded={isAntiRugSettings}
          trigger={
            <Controller
              name="template.antiRug"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <SwitchInline
                  label="Anti-Rug"
                  tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                  onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    setIsAntiRugSettings(checked)
                    onChange(checked)
                  }}
                  {...field}
                />
              )}
            />
          }
        >
          <Grid container rowGap={2}>
            <Controller
              name="template.antiRugThreshold"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <PercentsInput
                  label="Anti-Rug Threshold"
                  onOptionSelect={(value: any) => field.onChange(value)}
                  tooltipInfo="Bot will sell your position upon a liquidity removal event or any honeypot attempt on token smart contract."
                  {...field}
                />
              )}
            />
            <Controller
              name="template.sellRugExtraTip"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder="Amount"
                  isNumeric
                  label="Sell Rug Extra Tip"
                  tooltipInfo="The amount of Gwei that will be added over developer transaction to prioritize your transaction."
                  {...field}
                />
              )}
            />
          </Grid>
        </HiddenInputs>
      )} */}
      {!currentChain.features?.noAntiDuplicate && (
        <Grid container flexWrap="nowrap" gap={3} flexDirection={isResponsive ? 'column' : 'row'}>
          <Controller
            disabled={degenChadMode}
            name="template.shields.anti_duplicate"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <SwitchInline
                label="Anti Duplicate"
                tooltipInfo="Does not purchase the token if the user already owns it."
                {...field}
              />
            )}
          />
        </Grid>
      )}

      {(currentChain.features?.maxBuyTaxForTemplates ||
        currentChain.features?.maxSellTaxForTemplates) && (
        <Grid container flexWrap="nowrap" gap={3} flexDirection={isResponsive ? 'column' : 'row'}>
          {!!currentChain.features?.maxBuyTaxForTemplates && (
            <Controller
              name="template.shields.buy_tax"
              control={control}
              disabled={degenChadMode}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder="Enter percentage"
                  isNumeric
                  label="Max Buy Tax"
                  adornmentText="%"
                  tooltipInfo="The percentage of tokens you will lose when buying. The amount of tax is specified by the developer in the smart contract. If the buy tax exceeds entered value, the transaction will fail."
                  error={!!errors.template?.shields?.buy_tax?.message}
                  {...field}
                />
              )}
            />
          )}
          {!!currentChain.features?.maxSellTaxForTemplates && (
            <Controller
              name="template.shields.sell_tax"
              control={control}
              disabled={degenChadMode}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  placeholder="Enter percentage"
                  isNumeric
                  label="Max Sell Tax"
                  adornmentText="%"
                  tooltipInfo="Maximum allowable tax percentage when selling a token. If the value is exceeded, the transaction will fail."
                  error={!!errors.template?.shields?.sell_tax?.message}
                  {...field}
                />
              )}
            />
          )}
        </Grid>
      )}
      <Grid container flexWrap="nowrap" gap={3} flexDirection={isResponsive ? 'column' : 'row'}>
        <Controller
          name="template.shields.minimum_liquidity"
          control={control}
          disabled={degenChadMode}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              placeholder="Enter min liquidity amount"
              isNumeric
              label="Min Liquidity"
              endAdornment={<EndAdornment label={chainSymbol} icon={(IconName as any)[iconName]} />}
              tooltipInfo="The minimum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is below the specified value, the transaction will fail."
              error={!!errors.template?.shields?.minimum_liquidity?.message}
              {...field}
            />
          )}
        />

        <Controller
          name="template.shields.maximum_liquidity"
          control={control}
          disabled={degenChadMode}
          render={({ field: { ref, ...field } }) => (
            <OptionalInput
              placeholder="Enter max liquidity amount"
              isNumeric
              label="Max Liquidity"
              endAdornment={<EndAdornment label={chainSymbol} icon={(IconName as any)[iconName]} />}
              tooltipInfo="The maximum allowable volume of the token liquidity pool. If at the time of transaction the liquidity is higher the specified value, the transaction will fail."
              error={!!errors.template?.shields?.maximum_liquidity?.message}
              {...field}
            />
          )}
        />
      </Grid>

      <Controller
        name="template.shields.maximum_market_cap"
        control={control}
        disabled={degenChadMode}
        render={({ field: { ref, ...field } }) => (
          <OptionalInput
            placeholder="Enter max marketcap"
            isNumeric
            label="Max Marketcap"
            adornmentText="$"
            tooltipInfo="The upper limit of the token's market capitalisation. The transaction will fail if the market cap exceeds the specified value at the moment of its processing."
            error={!!errors.template?.shields?.maximum_market_cap?.message}
            {...field}
          />
        )}
      />
    </Grid>
  )
}

export { ManualTemplateSafetyTab }
