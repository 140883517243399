import { Dispatch, FC, SetStateAction } from 'react'

import cn from 'classnames'

import useCheckUser from '@/hooks/useCheckUser'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button, ButtonIcon, Icon, Typography } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'
import { ESidebarBtnType } from '@/libs/enums/sidebar-btn-type'
import { useAppSelector } from '@/store'

import { CollapsedIcon } from './libs/components/collapsed-icon'
import { FavoritesAccordion } from './libs/components/favorites-accordion'
import { HoldingsAccordion } from './libs/components/holdings-accordion'
import { TemplatesAccordion } from './libs/components/templates-accordion'
import { WalletAccordion } from './libs/components/wallet-accordion'
import { WithTooltip } from './libs/components/with-tooltip'
import styles from './styles.module.scss'

type Props = {
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
}

const SidebarMinimised: FC<Props> = ({ collapsed, setCollapsed }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const navigate = useCustomNavigate()
  const checkUserAndExecute = useCheckUser()
  const windowDimension = useWindowDimensions()
  const isResponsive = windowDimension.width <= 1199

  const toggleSidebar = () => {
    setCollapsed((prev) => !prev)
  }

  return (
    <>
      <div
        className={cn(styles.wrapper, {
          [styles.collapsed]: collapsed,
        })}
      >
        {!isResponsive && (
          <div className={styles.arrowContainer} onClick={toggleSidebar}>
            <Icon name={IconName.DOUBLE_ARROW_LEFT} />
          </div>
        )}

        {isResponsive && (
          <div className={styles.userHub}>
            <Typography variant="body1" textColor="white">
              User hub
            </Typography>

            <ButtonIcon icon={IconName.CLOSE} onClick={toggleSidebar} />
          </div>
        )}

        <div>
          <div className={styles.btnContainer}>
            <WithTooltip title="Deposit" isOpen={!collapsed}>
              <Button
                styleVariant="black"
                className={styles.button}
                onClick={() =>
                  checkUserAndExecute(() => {
                    navigate({
                      isDashboard: true,
                      path: `${AppRoute.MODAL}/${AppRoute.SELECT_WALLET_TO_DEPOSIT}`,
                    })
                  })
                }
              >
                <Icon name={IconName.DEPOSIT} /> <span className={styles.btnLabel}>Deposit</span>
              </Button>
            </WithTooltip>
            {!!currentChain.features?.transfers && (
              <>
                <WithTooltip title="Transfer" isOpen={!collapsed}>
                  <Button
                    styleVariant="black"
                    className={styles.button}
                    onClick={() =>
                      checkUserAndExecute(() => {
                        navigate({
                          isDashboard: true,
                          path: `${AppRoute.MODAL}/${AppRoute.TRANSFER_WALLETS}`,
                          searchParams: {
                            type: ESidebarBtnType.TRANSFER,
                          },
                        })
                      })
                    }
                  >
                    <Icon name={IconName.WITHDRAW} />
                    <span className={styles.btnLabel}>Transfer</span>
                  </Button>
                </WithTooltip>
                <WithTooltip title="Disperse/Collect" isOpen={!collapsed}>
                  <Button
                    styleVariant="black"
                    className={styles.button}
                    onClick={() =>
                      checkUserAndExecute(() => {
                        navigate({
                          isDashboard: true,
                          path: `${AppRoute.MODAL}/${AppRoute.TRANSFER_WALLETS}`,
                          searchParams: {
                            type: ESidebarBtnType.DISPERSE_COLLECT,
                          },
                        })
                      })
                    }
                  >
                    <Icon name={IconName.CROSS} />
                    <span className={styles.btnLabel}>Disperse/Collect</span>
                  </Button>
                </WithTooltip>
              </>
            )}
            {!!currentChain.features?.bridge && (
              <WithTooltip title="Bridge" isOpen={!collapsed}>
                <Button
                  styleVariant="black"
                  className={cn(styles.button, styles.bridge)}
                  onClick={() =>
                    checkUserAndExecute(() => {
                      navigate({
                        isDashboard: true,
                        path: `${AppRoute.MODAL}/${AppRoute.BRIDGE}`,
                        state: null,
                      })
                    })
                  }
                >
                  <Icon name={IconName.BRIDGE} />
                  <span className={styles.btnLabel}>Bridge</span>
                </Button>
              </WithTooltip>
            )}
          </div>

          <div className={styles.accordionsWrapper}>
            <div className={styles.accordionsContainer}>
              <WalletAccordion isOpen={!collapsed} />
              <TemplatesAccordion isOpen={!collapsed} />
              <FavoritesAccordion isOpen={!collapsed} />
              <HoldingsAccordion isOpen={!collapsed} />
            </div>
            <div className={styles.accordionIcons}>
              <CollapsedIcon
                iconName={IconName.WALLET_GREY}
                label="Wallets"
                redirectTo={`${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`}
              />
              <CollapsedIcon
                iconName={IconName.TEMPLATES_GREY}
                label="Templates"
                redirectTo={`${AppRoute.MODAL}/${AppRoute.TEMPLATE_MANAGEMENT}`}
              />
              <CollapsedIcon
                iconName={IconName.STAR_GREY_BOLD}
                label="Favorites / History"
                redirectTo={`${AppRoute.MODAL}/${AppRoute.FAVORITES_HISTORY}`}
              />
              <CollapsedIcon
                iconName={IconName.HOLDINGS_GREY}
                label="Holdings"
                redirectTo={`${AppRoute.MODAL}/${AppRoute.HOLDINGS}`}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={cn(styles.overlay, { [styles.sidebarOpen]: !collapsed })}
        onClick={(e) => {
          e.stopPropagation()
          setCollapsed(true)
        }}
      />
    </>
  )
}

export { SidebarMinimised }
