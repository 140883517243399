import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DynamicEmbeddedWidget, useDynamicContext } from '@dynamic-labs/sdk-react-core'
import { isSolanaWallet } from '@dynamic-labs/solana'
import bs58 from 'bs58'

import { getStateOfUser, signIn } from '@/api/user'
import { CustomToast } from '@/components/custom-toast/custom-toast'
import { useCustomNavigate } from '@/hooks/useCustomNavigate'
import { useDynamicLogout } from '@/hooks/useDynamicLogout'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { chainsConfig } from '@/libs/configs/chains.config'
import { AppRoute } from '@/libs/enums'
import { TChainConfig } from '@/libs/types/chain.type'
import { useAppDispatch, useAppSelector } from '@/store'
import { setCurrentChain } from '@/store/slices/chain.slice'
import { fetchUserBuyTemplates, fetchUserById } from '@/store/slices/user.slice'
import { getNonceFromMessage } from '@/utils/get-nonce-from-message'

type Props = {
  isAuth?: boolean
}

const ConnectWallet = ({ isAuth }: Props) => {
  const navigate = useCustomNavigate()
  const [searchParams] = useSearchParams()

  const dispatch = useAppDispatch()
  const authData = useAppSelector((state) => state.auth.authData)

  const handleNewTokenSelection = useSelectNewToken()

  const isMobile = useIsMobile()

  const { primaryWallet } = useDynamicContext()
  const { dynamicLogout } = useDynamicLogout()

  type AuthData = typeof authData
  type PrimaryWallet = typeof primaryWallet

  const signInProcess = async ({
    networkId,
    address,
    signature,
    nonce,
    messageToSign,
    chain,
  }: {
    networkId: number
    address: string
    signature: string
    nonce: string
    messageToSign: string
    chain: TChainConfig
  }) => {
    try {
      const res = await signIn({
        blockchain: networkId,
        signatory: address,
        signature,
        nonce,
        message: messageToSign,
      })
      const { token, refresh_token, id } = res.data.data

      localStorage.setItem('token', token)
      localStorage.setItem('refreshToken', refresh_token)

      await dispatch(fetchUserById(id)).unwrap()
      dispatch(fetchUserBuyTemplates())

      navigate({
        path: isAuth
          ? AppRoute.DASHBOARD
          : localStorage.code
            ? AppRoute.ADD_DEVICE
            : localStorage.cachedPath,
        isDashboard: !localStorage.code,
        newChain: chain.description.toLowerCase(),
        searchParams: localStorage.code
          ? { code: localStorage.code }
          : { token: searchParams.get('token') ?? chain.defaultToken },
      })
    } catch (e: any) {
      const errCode = e?.response?.data?.data?.code
      if (errCode === 'Q9S7WRQT81') {
        localStorage.setItem(
          'signData',
          JSON.stringify({
            blockchain: networkId,
            signatory: address,
            signature,
            message: messageToSign,
            nonce,
          }),
        )
        navigate({
          path: isAuth ? `/${AppRoute.ENTER_CODE}` : `/${AppRoute.ENTER_CODE}`,
          searchParams: { wallet: address },
        })
      } else {
        CustomToast('error', e?.response?.data?.data.title)
      }
    }
  }
  const signInOrSignUp = async (
    { signedMessage, messageToSign }: NonNullable<AuthData>,
    primaryWallet: NonNullable<PrimaryWallet>,
  ) => {
    const nonce = getNonceFromMessage(messageToSign)

    const address = primaryWallet.address

    let signature = signedMessage
    let networkId = 1

    if (isSolanaWallet(primaryWallet)) {
      networkId = 8

      // Solana signature is base64 encoded on desktop, but not on mobile
      if (!isMobile) {
        signature = bs58.encode(Buffer.from(signature, 'base64'))
      }
    }

    const chain = chainsConfig.find((chain) => chain.id === networkId)!

    dispatch(setCurrentChain(chain))
    await handleNewTokenSelection(chain.defaultToken, {
      customChain: chain.description.toLowerCase(),
      skipRedirect: true,
    })

    const userState = await getStateOfUser(address)

    if (userState.data.data.exists) {
      await signInProcess({ networkId, address, signature, nonce, messageToSign, chain })
    } else {
      localStorage.setItem(
        'signData',
        JSON.stringify({
          blockchain: networkId,
          signatory: address,
          signature,
          message: messageToSign,
          nonce,
        }),
      )

      if (!isAuth) {
        localStorage.setItem(
          'account',
          JSON.stringify({
            signatory: address,
            signature,
            nonce,
          }),
        )
        navigate({ path: `/${AppRoute.ALREADY_SIGN}` })
      } else {
        await signInProcess({ networkId, address, signature, nonce, messageToSign, chain })
      }
    }
  }

  useEffect(() => {
    async function main() {
      if (authData && primaryWallet) {
        await signInOrSignUp(authData, primaryWallet)
        dynamicLogout()
      }
    }

    main()
  }, [authData, primaryWallet])

  return <DynamicEmbeddedWidget />
}

export { ConnectWallet }
