import { ReactNode } from 'react'

import cn from 'classnames'

import { Typography } from '@/libs/common'
import { ETokenInfo } from '@/libs/enums'
import {
  checkSimulationValue,
  formatNumber,
  formatPairSymbol,
  getClogColor,
  getPercentage,
  getTaxesColor,
} from '@/libs/helper'
import { TChainConfig } from '@/libs/types/chain.type'
import { TTokenInfo } from '@/libs/types/token-info-response.type'

import styles from './styles.module.scss'

enum EMaxWindowSize {
  LARGE = 1600,
  DESKTOP = 1400,
  MEDIUM = 1200,
}

type TFunctionParams = { currentChain: TChainConfig; simulation: any; currentToken: TTokenInfo }

type TokenInfoItem = {
  title: string
  getValue: (params: TFunctionParams) => ReactNode
  tooltipInfo?: string | ((params: TFunctionParams) => string)
  isCentered?: boolean
  className?: string | ((params: TFunctionParams) => string)
  minWindowWidth?: number
  forLite?: boolean
  forPro?: boolean
}

const tokenInfoConfig: Record<ETokenInfo, TokenInfoItem> = {
  [ETokenInfo.PRICE]: {
    title: 'Price',
    isCentered: true,
    forPro: true,
    getValue: ({ simulation }) => (
      <div className={styles.primaryText}>${formatNumber(simulation.price.usd).formatted}</div>
    ),
  },
  [ETokenInfo.MCAP]: {
    title: 'Marketcap',
    isCentered: true,
    forPro: true,
    getValue: ({ simulation }) => `$${formatNumber(simulation.market_cap).raw}`,
    className: styles.greenText,
  },
  [ETokenInfo.GAIN]: {
    title: 'Gain',
    tooltipInfo: 'How much the token has grown from the first candle to the current moment.',
    isCentered: true,
    forPro: true,
    getValue: ({ simulation }) => `${simulation?.gain || 0}x`,
    className: styles.greenText,
  },
  [ETokenInfo.LIQUIDITY]: {
    title: 'Liquidity',
    tooltipInfo: ({ currentChain }) =>
      `The volume of ${currentChain.chainSymbol} in Liquidity Pool. Other services may show doubled volume as they include tokenized half of LP.`,
    isCentered: true,
    forPro: true,
    getValue: ({ simulation, currentToken }) => (
      <>
        <div>${formatNumber(simulation.liquidity.pair.value_usd).formatted}</div>

        {checkSimulationValue(simulation.liquidity.pair.reserve) && (
          <div className={styles.additionalInfo}>
            <>
              {formatNumber(simulation.liquidity.pair.reserve || '0').formatted}{' '}
              {!!simulation.liquidity.pair.address &&
                formatPairSymbol(simulation.liquidity.pair.address, currentToken.pair)}
            </>
          </div>
        )}
      </>
    ),
    className: styles.defaultColor,
  },
  [ETokenInfo.TRADE_OPEN]: {
    title: 'Trade Open',
    tooltipInfo:
      'The token is tradeable. Sometimes, token trading may be disabled until the developer calls the appropriate function.',
    isCentered: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation.tradeable ? 'Yes' : 'No'),
    className: ({ simulation }) => (simulation?.tradeable ? styles.greenText : styles.redText),
  },
  [ETokenInfo.MAX_BUY_PER_WALLET]: {
    title: 'Max Buy / Wallet',
    tooltipInfo:
      'How many tokens can be purchased per transaction / how many tokens can be held per wallet.',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.LARGE,
    forPro: true,
    getValue: ({ simulation }) => (
      <>
        <div>
          {`${getPercentage(simulation.limits.buy.percentage)} /
            ${getPercentage(simulation.limits.wallet.percentage || '0')}`}
        </div>
        <div className={styles.additionalInfo}>
          {checkSimulationValue(simulation.limits.buy.cost_chain_currency)
            ? formatNumber(simulation.limits.buy.cost_chain_currency).formatted
            : '-'}{' '}
          /{' '}
          {checkSimulationValue(simulation.limits.wallet.cost_chain_currency)
            ? formatNumber(simulation.limits.wallet.cost_chain_currency || '0').formatted
            : '-'}
        </div>
      </>
    ),
    className: styles.defaultColor,
  },
  [ETokenInfo.TAXES]: {
    title: 'Tax Buy / Sell / Transfer',
    tooltipInfo:
      'The amount of tax set by the developer. Means how many tokens from your transaction will be transferred to the developer when buying / selling / transferring.',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.MEDIUM,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => {
      if (!simulation?.taxes) return null
      return (
        <>
          <span className={cn(styles[getTaxesColor(simulation.taxes.buy)])}>
            {getPercentage(simulation.taxes.buy)}
          </span>
          {' / '}
          <span className={cn(styles[getTaxesColor(simulation.taxes.sell)])}>
            {getPercentage(simulation.taxes.sell)}
          </span>
          {' / '}
          <span className={cn(styles[getTaxesColor(simulation.taxes.transfer)])}>
            {getPercentage(simulation.taxes.transfer)}
          </span>
        </>
      )
    },
  },
  [ETokenInfo.HONEYPOT]: {
    title: 'Honeypot',
    tooltipInfo: 'If the token is a Honeypot, purchased tokens will not be able to be sold.',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.MEDIUM,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation?.honeypot ? 'Yes' : 'No'),
    className: ({ simulation }) => (!simulation?.honeypot ? styles.greenText : styles.redText),
  },
  [ETokenInfo.RENOUNCED]: {
    title: 'Renounced',
    tooltipInfo:
      "When a smart contract is renounced, the developer can't call functions on it. It's safe when it's renounced.",
    isCentered: true,
    minWindowWidth: EMaxWindowSize.MEDIUM,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation?.renounced ? 'Yes' : 'No'),
    className: ({ simulation }) => (simulation?.renounced ? styles.greenText : styles.redText),
  },
  [ETokenInfo.BURN_LP]: {
    title: 'LP Burned',
    tooltipInfo:
      'Indicates how much of the liquidity pool has been burned. The developer has no control over the burned pool. The best value is 100%.',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.MEDIUM,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) =>
      simulation?.liquidity.burned === '-' ? '-' : `${simulation?.liquidity.burned || 0}%`,
    className: ({ simulation }) =>
      Number(simulation?.liquidity?.burned || '0') > 50 ? styles.greenText : styles.redText,
  },
  [ETokenInfo.CLOG]: {
    title: 'Clog',
    tooltipInfo:
      'How many tokens are stored in the smart contract waiting to be sold. The best value is 0%.',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.DESKTOP,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation?.clog ? `${simulation.clog}%` : '-'),
    className: ({ simulation }) => styles[getClogColor(+(simulation?.clog || '0'))],
  },
  [ETokenInfo.MAX_BUY]: {
    title: 'Max Buy',
    tooltipInfo:
      'How many tokens can be purchased per transaction / how many tokens can be held per wallet.',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.LARGE,
    forLite: true,
    getValue: ({ simulation }) =>
      simulation && (
        <Typography variant="body2" className={styles.defaultColor}>
          <div>{getPercentage(simulation.limits.buy.percentage)}</div>
          <div className={styles.additionalInfo}>
            {checkSimulationValue(simulation.limits.buy.cost_chain_currency) ? (
              <>${formatNumber(simulation.limits.buy.cost_chain_currency).formatted}</>
            ) : (
              '-'
            )}
          </div>
        </Typography>
      ),
  },
  [ETokenInfo.MAX_SELL]: {
    title: 'Max Sell',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.LARGE,
    forLite: true,
    getValue: ({ simulation }) =>
      simulation && (
        <Typography variant="body2" className={styles.defaultColor}>
          <div>{getPercentage(simulation.limits.sell.percentage)}</div>
          <div className={styles.additionalInfo}>
            {checkSimulationValue(simulation.limits.sell.cost_chain_currency) ? (
              <>${formatNumber(simulation.limits.sell.cost_chain_currency).formatted}</>
            ) : (
              '-'
            )}
          </div>
        </Typography>
      ),
  },
  [ETokenInfo.MAX_HOLD]: {
    title: 'Max Hold',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.LARGE,
    forLite: true,
    getValue: ({ simulation }) =>
      simulation && (
        <Typography variant="body2" className={styles.defaultColor}>
          <div>{getPercentage(simulation.limits.wallet.percentage)}</div>
          <div className={styles.additionalInfo}>
            {checkSimulationValue(simulation.limits.wallet.cost_chain_currency) ? (
              <>${formatNumber(simulation.limits.wallet.cost_chain_currency).formatted}</>
            ) : (
              '-'
            )}
          </div>
        </Typography>
      ),
  },
  [ETokenInfo.MINT_AUTH]: {
    title: 'Mint Auth',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.MEDIUM,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation?.mint_auth ? 'Enabled' : 'Disabled'),
    className: ({ simulation }) => (simulation?.mint_auth ? styles.redText : styles.greenText),
  },
  [ETokenInfo.FREEZE_AUTH]: {
    title: 'Freeze Auth',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.MEDIUM,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation?.freeze_auth ? 'Enabled' : 'Disabled'),
    className: ({ simulation }) => (simulation?.freeze_auth ? styles.redText : styles.greenText),
  },
  [ETokenInfo.TOKEN_DATA_MUTABLE]: {
    title: 'Token Data Mutable',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.MEDIUM,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation?.data_mutable ? 'Yes' : 'No'),
    className: ({ simulation }) => (simulation?.data_mutable ? styles.redText : styles.greenText),
  },
  [ETokenInfo.UPDATE_AUTH]: {
    title: 'Update Auth',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.DESKTOP,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation.update_auth ? 'Yes' : 'No'),
    // className: ({simulation}) => (simulation.update_auth ? styles.redText : styles.greenText),
  },
  [ETokenInfo.DEV_SOLD]: {
    title: 'Dev Sold',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.DESKTOP,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => (simulation?.dev_sold ? 'Yes' : 'No'),
    className: ({ simulation }) => (simulation?.dev_sold ? styles.redText : styles.greenText),
  },
  [ETokenInfo.HOLDERS]: {
    title: 'Holders',
    isCentered: true,
    minWindowWidth: EMaxWindowSize.DESKTOP,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => simulation?.holders_count?.toString() || '-',
  },
  [ETokenInfo.TOP_10_HOLDERS]: {
    title: 'Top 10 holders',
    minWindowWidth: EMaxWindowSize.LARGE,
    forLite: true,
    forPro: true,
    getValue: ({ simulation }) => simulation?.top10Holders || '-',
  },
}

export { tokenInfoConfig }
