import { FC, useEffect, useState } from 'react'

import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import Stack, { StackProps } from '@mui/material/Stack'
import cn from 'classnames'

import { VerticalGroup } from '@/components/buy-sell-snipe-profit-sell/index'
import { Currency } from '@/components/currency'
import { PositionSoldCard } from '@/components/profit-sell-tab/libs/components'
import stylesCardCommon from '@/components/profit-sell-tab/libs/components/styles-cards.module.scss'
import { Spinner } from '@/components/spinner'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Checkbox, type TCheckboxProps, Typography } from '@/libs/common'
import { AppRoute, IconName, PositionCardVariant, SpinnerSize } from '@/libs/enums'
import { formatNumber, getPriceImpactColor } from '@/libs/helper'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { PositionDTO, ValueOf } from '@/libs/types/index'
import { TActivePanelItem } from '@/libs/types/panels-data-socket-response.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TPositionCardProps = StackProps & {
  positionData: PositionDTO
  position?: TActivePanelItem
  checkboxProps?: TCheckboxProps
  variant: ValueOf<typeof PositionCardVariant>
  message: React.ReactElement
  isSold?: boolean
  worth: string
  isMarketSellLoading?: boolean
  onMarketSellAll?: () => Promise<null>
  onSellInitial?: () => Promise<null>
}

const soldTokenData = {
  initialValue: '12.580000 PEPE',
  soldValue: '2.344546 ETH',
  percent: '434.34',
}

const defaultLoadingState = {
  initial: false,
  all: false,
}

const PositionCard: FC<TPositionCardProps> = ({
  className,
  message,
  variant,
  positionData,
  position,
  checkboxProps,
  isSold,
  isMarketSellLoading,
  onMarketSellAll,
  onSellInitial,
  ...props
}) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const [loadingState, setLoadingState] = useState(defaultLoadingState)
  const [soldMessage, setSoldMessage] = useState(isSold)
  const [isSellAll, setIsSellAll] = useState(false)
  const [isSellInitial, setIsSellInitial] = useState(false)

  const navigate = useCustomNavigate()

  useEffect(() => {
    setSoldMessage(isSold)
  }, [isSold])

  // const handleSellAll = () => {
  //   setIsSellAll(!isSellAll)
  // }

  const handleSellInitial = () => {
    if (onSellInitial) {
      setLoadingState({ ...defaultLoadingState, initial: true })
      onSellInitial().then(() => {
        setLoadingState({ ...defaultLoadingState, initial: false })
      })
    }
  }

  // const handleOpenSettings = () => {
  //   setOpenSettings(!openSettings)
  // }

  // const handleActive = (_: BaseSyntheticEvent, newValue: boolean) => {
  //   // sending request to pause all positins related to this card
  //   setIsActiveCard(newValue)
  // }
  const handleMarketSellAll = () => {
    if (onMarketSellAll) {
      setLoadingState({ ...defaultLoadingState, all: true })
      onMarketSellAll().then(() => {
        setLoadingState({ ...defaultLoadingState, all: false })
      })
    }
  }

  const handleCloseSellMessage = () => {
    if (isSellAll) {
      setIsSellAll(false)
    } else if (isSellInitial) {
      setIsSellInitial(false)
    }
  }

  // const handleOpenEditPosition = () => {
  //   navigate(`${AppRoute.MODAL}/${AppRoute.EDIT_POSITION}/${positionData.id}`)
  // }

  const handleOpenShareProfitModal = () => {
    navigate({
      path: `${AppRoute.MODAL}/${AppRoute.SHARE_PROFIT}`,
      isDashboard: true,
      state: { positionsSummary: position, positionData: positionData },
    })
  }

  const getUsdValue = (value: number | string) => {
    if (!positionData.tokenUsdPrice) {
      return
    }

    return <>~${formatNumber(+value * positionData.tokenUsdPrice, 2).formatted}</>
  }

  const isHoneypotWithLiquidity = position && position.h && position.l > 0

  return (
    <>
      {!isSellAll && !isSellInitial && (
        <Stack
          className={cn(
            stylesCardCommon.card,
            styles.positionCard,
            {
              [styles.border]: checkboxProps?.checked,
              // [stylesCardCommon.inactiveCard]: !isActiveCard,
              [styles.sold]: soldMessage,
            },
            className,
          )}
          {...props}
        >
          {soldMessage ? (
            message
          ) : (
            <>
              <div className={styles.headInfo}>
                {/* header row */}

                <Grid
                  container
                  wrap="nowrap"
                  alignItems="align-center"
                  justifyContent="space-between"
                  className={stylesCardCommon.rowContainer}
                >
                  <Grid container className={stylesCardCommon.rowContainerItem}>
                    <Checkbox {...checkboxProps} />
                    <Typography variant="body2">{hideWalletAddress(positionData.id)}</Typography>
                  </Grid>
                  {/* <Grid container className={stylesCardCommon.rowContainerItem}>
                    <Typography variant="body2">Position active</Typography>
                    <Switch checked={isActiveCard} onChange={handleActive} />
                  </Grid> */}
                  <Grid container gap={1} className={stylesCardCommon.headerButtonsGroup}>
                    {
                      <Button
                        className={stylesCardCommon.headerButton}
                        styleVariant="borderless-gold"
                        iconStartName={IconName.SHARE}
                        transparent
                        onClick={handleOpenShareProfitModal}
                      >
                        Share
                      </Button>
                    }
                    {/* <Button
                      className={stylesCardCommon.headerButton}
                      styleVariant="borderless-gold"
                      iconStartName={IconName.EDIT}
                      transparent
                      onClick={handleOpenEditPosition}
                      disabled
                    >
                      Edit position
                    </Button> */}
                  </Grid>
                </Grid>
                {/* middle rows */}
                <Grid
                  container
                  wrap="nowrap"
                  justifyContent="space-between"
                  direction="column"
                  className={stylesCardCommon.rowContainer}
                  gap={1}
                >
                  <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    justifyContent="space-between"
                    className={stylesCardCommon.rowContainer}
                  >
                    <Grid container gap={1}>
                      <Box display="flex" className={styles.middleRow}>
                        <VerticalGroup
                          title={'Balance'}
                          className={styles.positionGroup}
                          descClassName="positionDescription"
                          description={
                            <>
                              {formatNumber(positionData.totalBalance).formatted}{' '}
                              {positionData.coin}
                            </>
                          }
                        />

                        {(positionData.profitOrLoss || positionData.profitOrLoss == 0) && (
                          <VerticalGroup
                            title={'Profit/Loss'}
                            description={
                              <span
                                className={cn(
                                  positionData.profitOrLoss > 0 && !isHoneypotWithLiquidity
                                    ? stylesCardCommon.isProfit
                                    : stylesCardCommon.isLoss,
                                )}
                              >
                                {'%'}
                                {Math.abs(
                                  !isHoneypotWithLiquidity ? positionData.profitOrLoss : 100,
                                ).toFixed(3)}
                              </span>
                            }
                          />
                        )}

                        {!!positionData.percentageOfSupply && (
                          <Typography variant="caption" resetLineHeight>
                            {formatNumber(positionData.percentageOfSupply).formatted}%
                          </Typography>
                        )}

                        {positionData.supply && (
                          <Typography variant="body2">
                            {formatNumber(`${positionData.supply}`).formatted}%
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid container className={stylesCardCommon.middleButtonsGroup}>
                      {variant === PositionCardVariant.PROFIT && (
                        <>
                          <Button
                            styleVariant="black"
                            className={cn(stylesCardCommon.middleRowButton, styles.buttonSellAll)}
                            transparent
                            onClick={handleMarketSellAll}
                            isLoading={loadingState.all}
                          >
                            Market sell
                          </Button>
                          <Button
                            styleVariant="black"
                            className={cn(
                              stylesCardCommon.middleRowButton,
                              styles.buttonSellInitial,
                            )}
                            transparent
                            onClick={handleSellInitial}
                            isLoading={loadingState.initial}
                          >
                            Sell initial
                          </Button>
                        </>
                      )}
                      {variant === PositionCardVariant.SELL && (
                        <Button
                          styleVariant="black"
                          className={cn(
                            stylesCardCommon.middleRowButton,
                            styles.buttonSellAll,
                            styles.fixedWidth,
                          )}
                          transparent
                          onClick={handleMarketSellAll}
                          isLoading={loadingState.all}
                          disabled={loadingState.all}
                        >
                          {isMarketSellLoading ? (
                            <Spinner size={SpinnerSize.SMALL} />
                          ) : (
                            'Market sell all'
                          )}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  {/* <Grid container>
                  <Button
                    className={stylesCardCommon.transferButton}
                    styleVariant="borderless-gold"
                    iconStartName={IconName.WITHDRAW}
                    transparent
                    disabled
                  >
                    Transfer to backup wallets
                  </Button>
                </Grid> */}
                </Grid>
              </div>
              <Grid
                container
                wrap="nowrap"
                alignItems="center"
                // justifyContent="space-between"
                className={cn(stylesCardCommon.rowContainer, styles.infoContainer)}
              >
                <VerticalGroup
                  title={'Cost'}
                  description={
                    <>
                      {formatNumber(positionData.cost, 3).formatted} <Currency />
                    </>
                  }
                  subValue={getUsdValue(positionData.cost)}
                />
                <VerticalGroup
                  title={'Worth'}
                  descClassName={+positionData?.worth > +positionData.cost ? 'greenText' : ''}
                  description={
                    <>
                      {!isHoneypotWithLiquidity ? formatNumber(positionData.worth, 3).formatted : 0}{' '}
                      <Currency />
                    </>
                  }
                  subValue={!isHoneypotWithLiquidity && getUsdValue(positionData.worth)}
                />
                {!!currentChain.features?.afterTax &&
                  (positionData.afterTax || positionData.afterTax == 0) && (
                    <VerticalGroup
                      title={'After Tax'}
                      descClassName={positionData.afterTax > +positionData.cost ? 'greenText' : ''}
                      description={
                        <>
                          {!isHoneypotWithLiquidity
                            ? formatNumber(`${positionData.afterTax}`, 3).formatted
                            : 0}{' '}
                          <Currency />
                          {/* {positionData.coinCompairedWith} */}
                        </>
                      }
                      subValue={!isHoneypotWithLiquidity && getUsdValue(positionData.afterTax)}
                    />
                  )}
                {(positionData.sold || positionData.sold === 0) && (
                  <VerticalGroup
                    title={'Sold'}
                    description={
                      <>
                        {!isHoneypotWithLiquidity
                          ? formatNumber(positionData.sold.toString(), 3).formatted
                          : 0}{' '}
                        <Currency />
                      </>
                    }
                    subValue={!isHoneypotWithLiquidity && getUsdValue(positionData.sold)}
                  />
                )}
                {(positionData.priceImpact || positionData.priceImpact == 0) && (
                  <VerticalGroup
                    title={'Price impact'}
                    descClassName={getPriceImpactColor(positionData.priceImpact)}
                    description={
                      <>
                        {!isHoneypotWithLiquidity
                          ? formatNumber(positionData.priceImpact.toString(), 3).formatted
                          : 0}
                        %
                      </>
                    }
                  />
                )}
              </Grid>
              {/* footer row */}
              {/* onClick={handleOpenSettings} */}
              {variant === PositionCardVariant.PROFIT && (
                <>
                  {/* <Button
                    borderless
                    transparent
                    contentMaxWidth
                    disableRipple
                    onClick={handleOpenSettings}
                    className={styles.settingsButton}
                  >
                    <Grid
                      container
                      wrap="nowrap"
                      alignItems="center"
                      justifyContent="center"
                      className={cn(stylesCardCommon.rowContainer, styles.toggleSettings)}
                      gap={2}
                    >
                      <Typography variant="body2" textColor="light-grey">
                        Position Settings
                      </Typography>
                      <div className={styles.divider}></div>
                      <div className={cn({ [styles.iconExpanded]: openSettings })}>
                        <Icon name={IconName.EXPAND_MORE} />
                      </div>
                    </Grid>
                  </Button>
                  {!!positionData.settings && (
                    <div>
                      <Collapse in={openSettings} timeout="auto" unmountOnExit>
                        <PositionSettings
                          positionSettings={positionData.settings}
                          isActive={isActiveCard}
                        />
                      </Collapse>
                    </div>
                  )} */}
                </>
              )}
            </>
          )}
        </Stack>
      )}

      {(isSellAll || isSellInitial) && (
        <PositionSoldCard
          isSellAll={isSellAll}
          isSellInitial={isSellInitial}
          soldTokenData={soldTokenData}
          handleCloseSellMessage={handleCloseSellMessage}
          setIsSellInitial={setIsSellInitial}
          tokenInfo={positionData}
        />
      )}
    </>
  )
}

export { PositionCard, type TPositionCardProps }
