import { ChainName } from '@/libs/enums'

const chainSensitiveLabels = {
  buyPriority: {
    default: 'Buy Priority',
    [ChainName.BNB]: 'Buy Gwei',
  },
  sellPriority: {
    default: 'Sell Priority',
    [ChainName.BNB]: 'Sell Gwei',
  },
  approvePriority: {
    default: 'Approve Priority',
    [ChainName.BNB]: 'Approve Gwei',
  },
  setupDefaultPriorities: {
    default: 'Setup Default Priorities',
    [ChainName.BNB]: 'Setup Default Gwei',
  },
  bribeAmount: {
    default: 'Bribe Amount',
  },
  gas: {
    default: 'Miner Tip',
    [ChainName.BNB]: 'Gas',
    [ChainName.SOLANA]: 'Buy Priority',
  },
}

export { chainSensitiveLabels }
