import { FC, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { jwtDecode } from 'jwt-decode'

import { EAuthQueryParams } from '@/components/app/libs/enums/auth-query-params.enum'
import { CustomToast } from '@/components/custom-toast'
import { useCurrentChain } from '@/hooks/useCurrentChain.ts'
import { useSelectNewToken } from '@/hooks/useSelectNewToken.hook'
import { authRoutes } from '@/libs/configs/auth-routes.config'
import { AppRoute, LocalStorageItem, TabName } from '@/libs/enums'
import { handleAppVersionChange } from '@/libs/helper'
import { isAppMode } from '@/libs/helper/isAppMode'
import { useAppDispatch } from '@/store'
import { getBrowserInfo } from '@/store/slices/app.slice'
import { setCurrentChain } from '@/store/slices/chain.slice.ts'
import { fetchUserBuyTemplates, fetchUserById } from '@/store/slices/user.slice'

type TProps = {
  setIsAppLoaded: (value: boolean) => void
}

const AuthController: FC<TProps> = ({ setIsAppLoaded }) => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()
  const location = useLocation()
  const chain = useCurrentChain()
  const handleNewTokenSelection = useSelectNewToken()
  const routesArray = location.pathname.split('/dashboard/')
  const routeAppMode = routesArray.length > 1 && isAppMode(routesArray[1]) ? routesArray[1] : null

  const loadApp = () => {
    const tokenFromUrl = searchParams.get('token')

    let cachedToken = null
    try {
      const storageToken = localStorage[LocalStorageItem.TOKEN_ADDRESS]
      cachedToken = storageToken && JSON.parse(storageToken)
    } catch (err) {
      cachedToken = null
    }
    const storedAppMode = localStorage.getItem(LocalStorageItem.MODE)
    const settingNav = searchParams.get(TabName.SETTING_NAV)

    const initialToken =
      tokenFromUrl || (cachedToken?.chainId === chain.id ? cachedToken.token : chain.defaultToken)

    handleNewTokenSelection(initialToken, {
      replaceNavigation: true,
      skipRedirect:
        location.pathname === AppRoute.ADD_DEVICE ||
        location.pathname === AppRoute.PHANTOM_DEEPLINKS ||
        !!settingNav ||
        (!authRoutes.includes(location.pathname) && routeAppMode == storedAppMode),
      customAppMode: storedAppMode || routeAppMode,
      isForceNavigate: !!storedAppMode && routeAppMode !== storedAppMode,
      skipTokenSetToUrl: location.pathname !== AppRoute.PHANTOM_DEEPLINKS,
    })
    dispatch(fetchUserBuyTemplates())

    setIsAppLoaded(true)
  }

  const handleAuth = async () => {
    handleAppVersionChange()
    await dispatch(getBrowserInfo()).unwrap()
    dispatch(setCurrentChain(chain))

    // TODO: Consider checking the browser support
    // const isBrowserSupported = await dispatch(checkBrowserSupport()).unwrap()
    // if (!isBrowserSupported) {
    //   return
    // }

    const token = localStorage.token as string | undefined

    if (!token) {
      loadApp()
      return
    }

    const decoded = jwtDecode(token)
    const userId = decoded?.sub as string

    try {
      const userData = await dispatch(fetchUserById(userId)).unwrap()

      if (!userData) {
        loadApp()
        return
      }

      loadApp()
    } catch (err) {
      CustomToast('error', (err as any).message)
      loadApp()
    }
  }

  useEffect(() => {
    const affiliateId = searchParams.get(EAuthQueryParams.AFFILIATE_ID)

    if (affiliateId) {
      sessionStorage.setItem(EAuthQueryParams.AFFILIATE_ID, affiliateId)
    }
    handleAuth()
  }, [])

  return <></>
}

export { AuthController }
