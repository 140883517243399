import { FC, useEffect, useMemo, useState } from 'react'

import { BuyTab } from '@/components/buy-tab'
import { ProfitSellTab } from '@/components/profit-sell-tab'
import { SellTab } from '@/components/sell-tab'
import { TTabsProps, Tabs } from '@/libs/common/index'
import { LocalStorageItem, TemplateGroup } from '@/libs/enums'
import { TTab } from '@/libs/types'
import { useAppDispatch, useAppSelector } from '@/store'
import { clearCurrentSelectedTemplate } from '@/store/slices/user.slice'

import { SnipeTab } from './libs/components/snipe-tab'
// import { SnipeTab } from './libs/components/snipe-tab'
import styles from './styles.module.scss'

const excludeTabs: string[] = [TemplateGroup.SNIPE]

const BuySellSnipeProfitSell: FC<Omit<TTabsProps, 'tabs'>> = ({ className, ...props }) => {
  const dispatch = useAppDispatch()
  const { userCurrentSelectedTemplate } = useAppSelector((state) => state.user)
  const defaultPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const chainName = useAppSelector((state) => state.chain.currentChain.chainName)
  const [value, setValue] = useState<number | undefined>()

  useEffect(() => {
    if (userCurrentSelectedTemplate.group) {
      const tabIndex = tabs.findIndex(({ label }) => label === userCurrentSelectedTemplate.group)
      setValue(tabIndex)
    }
  }, [userCurrentSelectedTemplate])

  const tabs: TTab[] = useMemo(
    () =>
      [
        {
          label: TemplateGroup.BUY,
          content: defaultPriorities ? <BuyTab /> : <></>,
        },
        {
          label: 'Sell',
          content: <SellTab />,
        },
        {
          label: TemplateGroup.SNIPE,
          content: <SnipeTab />,
        },
        {
          label: 'Positions',
          content: <ProfitSellTab />,
        },
      ].filter((item) => !excludeTabs.includes(item.label)),
    [chainName, defaultPriorities],
  )
  const activePanelTab = localStorage.getItem(LocalStorageItem.ACTIVE_PANEL_TAB)
  const activeTabIndex = tabs.findIndex((tab) => tab.label == activePanelTab)
  const defaultTabIndex = activeTabIndex !== -1 ? activeTabIndex : 0
  return (
    <div className={styles.container}>
      <Tabs
        className={className}
        tabsClasses={styles.tabs}
        value={value}
        onChange={(_, value) => {
          dispatch(clearCurrentSelectedTemplate())
          localStorage.setItem(
            LocalStorageItem.ACTIVE_PANEL_TAB,
            value < tabs.length ? tabs[value].label : TemplateGroup.BUY,
          )
          setValue(value)
        }}
        {...props}
        tabs={tabs}
        defaultValue={tabs.length > Number(defaultTabIndex) ? defaultTabIndex : 0}
      />
    </div>
  )
}

export { BuySellSnipeProfitSell }
