import { FC, useEffect, useState } from 'react'

import cls from 'classnames'

import { setSetup } from '@/api/user'
import { ChainNameToNativeTokenIcon } from '@/components/header/libs/maps/chain-name-to-chain-element.tsx'
import { ButtonGroupRadio, ButtonGroupRadioButton } from '@/libs/common/button-group-radio'
import { ButtonIcon } from '@/libs/common/button-icon'
import { Input, TInputProps } from '@/libs/common/input/input'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProperty = TInputProps & {
  defaultOptions: (number | string)[]
  radioGroupName: string
  name: string
  onOptionSelect: (newValue: number | string) => void
  tooltipInfo?: string
  inputClassName?: string
  titleClassName?: string
}

const InputWithBuyAmount: FC<TProperty> = ({
  defaultOptions,
  disabled,
  radioGroupName,
  onOptionSelect,
  className,
  inputClassName = '',
  titleClassName = '',
  value,
  name,
  tooltipInfo,
  ...props
}) => {
  const [options, setOptions] = useState(defaultOptions)
  const [customOptions, setCustomOptions] = useState(defaultOptions)
  const [isCustomizeMode, setIsCustomizeMode] = useState(false)
  const userData = useAppSelector((state) => state.user.userData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const networkId = useAppSelector((state) => state.chain.currentChain.indexerChainId)

  const handleSubmitSetting = () => {
    if (!validateGroup()) return
    setOptions(customOptions)

    const keys = ['pro_buy_one', 'pro_buy_two', 'pro_buy_three', 'pro_buy_four']

    const result = keys.reduce((acc: any, key, index) => {
      acc[key] = customOptions[index]
      return acc
    }, {})
    setSetup(userData?.user_id, { ...result, blockchain: networkId })
    //localStorage.setItem(name, JSON.stringify(customOptions))
    setIsCustomizeMode(false)
  }

  const validateGroup = () => {
    let isValidValue = true
    customOptions.forEach((el) => {
      if (el === '' || el === '0') isValidValue = false
    })
    const isUnique = new Set(customOptions).size === customOptions.length
    return isUnique && isValidValue
  }

  const renderTitleIcon = () => {
    if (tooltipInfo) {
      return null
    }

    if (isCustomizeMode) {
      return (
        <div className={styles.controls}>
          <ButtonIcon icon={IconName.CHECK_SMALL} type="button" onClick={handleSubmitSetting} />
          <ButtonIcon
            icon={IconName.CLOSE_MIDDLE}
            type="button"
            onClick={() => {
              setCustomOptions(options)
              setIsCustomizeMode(false)
            }}
          />
        </div>
      )
    }

    return (
      <ButtonIcon
        className={styles.control}
        icon={IconName.EDIT_SMALL}
        type="button"
        onClick={() => setIsCustomizeMode(true)}
      />
    )
  }

  useEffect(() => {
    setOptions(defaultOptions)
  }, [currentChain])

  return (
    <div className={styles.container}>
      <div className={cls(styles.controlsWrapper, titleClassName)}>{renderTitleIcon()}</div>
      <Input
        isNumeric
        className={cls(styles.input, inputClassName)}
        value={value}
        disabled={disabled}
        name={name}
        tooltipInfo={tooltipInfo}
        {...props}
      />

      <ButtonGroupRadio
        exclusive
        value={value}
        onChange={(_, value) => {
          onOptionSelect(value)
        }}
      >
        {options?.map((el, index) => {
          return isCustomizeMode ? (
            <div>
              <Input
                isNumeric
                placeholder=""
                value={customOptions[index]}
                className={cls(styles.radioButton, styles.radioBtnInput, {
                  [styles.firstInput]: index === 0,
                  [styles.lastInput]: index === options.length - 1,
                })}
                onChange={(e) => {
                  const newValue = e.target.value
                  setCustomOptions((state) => {
                    const updatedState = [...state]
                    updatedState[index] = newValue
                    return updatedState
                  })
                }}
              />
            </div>
          ) : (
            <ButtonGroupRadioButton
              value={el}
              name="wallet"
              className={cls(styles.radioButton, {
                [styles.firstInput]: index === 0,
                [styles.lastInput]: index === options.length - 1,
              })}
              key={index}
            >
              {ChainNameToNativeTokenIcon[currentChain.chainName]}
              <Typography variant="body2" textColor="light-grey">
                {+el < 0.0001 || +el >= 1000000 ? formatNumber(el).formatted : el}
              </Typography>
            </ButtonGroupRadioButton>
          )
        })}
      </ButtonGroupRadio>
    </div>
  )
}

export { InputWithBuyAmount }
