import { FC } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Divider, Grid } from '@mui/material'
import cls from 'classnames'

import styles from '@/components/selected-token-info/styles.module.scss'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { ButtonIcon, Icon } from '@/libs/common'
import { TokenDataItem } from '@/libs/common/token-data-item'
import { tokenInfoConfig } from '@/libs/configs/token-info'
import { AppMode, IconName } from '@/libs/enums'
import { TTokenInfo } from '@/libs/types/token-info-response.type'
import { store, useAppSelector } from '@/store'

type TProps = {
  selectedToken: TTokenInfo
  handleOpenModal: () => void
}

const GeneralTokenInfo: FC<TProps> = ({ selectedToken, handleOpenModal }) => {
  const simulation = useAppSelector(
    (state) => state.chain.adaptedCurrentTokenSimulationWebsocket?.data,
  )
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const isDataAvailable = !!simulation
  const { width: windowWidth } = useWindowDimensions()
  const { mode } = useParams()

  if (!simulation) return null

  return (
    <>
      {currentChain.tokenInfo.map((field) => {
        const config = tokenInfoConfig[field]
        if (
          (!!config.minWindowWidth && windowWidth < config.minWindowWidth) ||
          (mode === AppMode.PRO && !config.forPro) ||
          (mode === AppMode.LITE && !config.forLite)
        ) {
          return <></>
        }

        const functionParams = { currentChain, simulation, currentToken: selectedToken }

        return (
          <>
            <Divider orientation="vertical" flexItem className={cls(styles.divider)} />
            <TokenDataItem
              key={config.title}
              title={config.title}
              tooltipInfo={
                typeof config.tooltipInfo === 'function'
                  ? config.tooltipInfo(functionParams)
                  : config.tooltipInfo
              }
              value={config.getValue(functionParams)}
              isDataAvailable={isDataAvailable}
              isCentered={config.isCentered}
              className={
                typeof config.className === 'function'
                  ? config.className(functionParams)
                  : config.className
              }
            />
          </>
        )
      })}

      <Divider orientation="vertical" flexItem className={cls(styles.divider)} />

      <div className={styles.liteTokenDataWrapper}>
        <ButtonIcon tooltipTitle="List" onClick={handleOpenModal}>
          <Icon name={IconName.LIST_ALT} />
        </ButtonIcon>
        <Link
          to={`${store.getState().chain.currentChain.explorer}/token/${selectedToken.token.address}`}
          target="_blank"
          className={styles.link}
        >
          <Icon name={IconName.ETHERSCAN_GOLD} />
        </Link>
      </div>
    </>
  )
}

export { GeneralTokenInfo }
