import { ReactNode } from 'react'

import { Box } from '@mui/material'
import cn from 'classnames'

import { Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import {
  checkSimulationValue,
  formatNumber,
  getClogColor,
  getPercentage,
  getTaxesColor,
} from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { TTokenInfo } from '@/libs/types/token-info-response.type'

import styles from '../styles.module.scss'

interface TokenInfoModalField {
  id: string
  title: string
  tooltipInfo?: string
  section: 'general' | 'trading' | 'audit'
  getValue: (simulation: any, token?: TTokenInfo) => ReactNode
  className?: (simulation: any) => string | undefined
  titleClassName?: string
}

const generalFields: TokenInfoModalField[] = [
  {
    id: 'priceChange',
    title: 'Price / Price change',
    section: 'general',
    titleClassName: styles.fieldDescription,
    getValue: (simulation, token) => (
      <>
        <Typography className={styles['ff-15']} textColor="color-grey-3" height="21px">
          ${formatNumber(simulation?.price.usd).formatted}
        </Typography>
        <Typography className={styles['ff-13']} textColor="color-grey-2">
          <span
            className={
              +Number(token?.token?.price_change_24h ?? '0') < 0
                ? styles.redText
                : token?.token?.price_change_24h !== '-'
                  ? styles.greenText
                  : ''
            }
          >
            {+(token?.token?.price_change_24h || 0) > 0 && '+'}
            {Math.abs(+(token?.token?.price_change_24h || 0) * 100).toFixed(2)}%
          </span>
          {simulation?.liquidity.burned !== '-' && <span> (24h)</span>}
        </Typography>
      </>
    ),
  },
  {
    id: 'liquidityMarketCap',
    title: 'Liq / MKT CAP',
    section: 'general',
    titleClassName: styles.fieldDescription,
    getValue: (simulation) => (
      <>
        <Box className={styles.liquidityAmount} alignItems="center" display="flex" gap={0.5}>
          <Typography className={styles['ff-15']} textColor="color-grey-3">
            {formatTokenPrice(simulation.liquidity.pair.value_usd).formatted}
          </Typography>
          <Icon name={IconName.LOCK_GREEN} />
        </Box>
        <Typography className={styles['ff-13']}>
          {formatTokenPrice(simulation.market_cap).raw}
        </Typography>
      </>
    ),
  },
  {
    id: 'volumeAndTxs',
    title: 'VOL / TXs',
    section: 'general',
    titleClassName: styles.fieldDescription,
    getValue: (simulation, token) => (
      <>
        <Typography className={styles['ff-15']} textColor="color-grey-3">
          ${formatNumber(+(token?.token?.volume_24h || '0')).formatted}
        </Typography>
        <Box display="flex">
          <Typography className={styles['ff-13']} textColor="color-grey-2">
            <span className={styles.txVolume}>
              {token?.statistics?.one_day?.non_currency?.transactions || ''}
            </span>
            <span className={styles.greenText}>
              {token?.statistics?.one_day?.non_currency?.buys || '-'}
            </span>
            <span className={cn(styles.txSellCount, styles.redText)}>
              {token?.statistics?.one_day?.non_currency?.sells || '-'}
            </span>
          </Typography>
        </Box>
      </>
    ),
  },
  {
    id: 'totalSupply',
    title: 'Total Supply',
    section: 'general',
    titleClassName: styles.fieldDescription,
    tooltipInfo: 'The total number of tokens that have been issued.',
    getValue: (simulation) => {
      return simulation?.total_supply != '-' ? (
        <>
          {formatNumber(simulation?.total_supply).formatted} {simulation.symbol}
        </>
      ) : (
        '-'
      )
    },
  },
  {
    id: 'ownerSupply',
    title: 'Owner Supply',
    section: 'general',
    titleClassName: styles.fieldDescription,
    tooltipInfo: 'The percentage of tokens held by the token creator.',
    getValue: (simulation) => `${simulation?.owner_supply || 0}%`,
    className: (simulation) => styles.fieldValue,
  },
  {
    id: 'holderCount',
    title: 'Holder Count',
    section: 'general',
    titleClassName: styles.fieldDescription,
    tooltipInfo: 'How many unique wallets hold the token.',
    getValue: (simulation) => simulation?.holders_count || 0,
    className: (simulation) => styles.fieldValue,
  },
  {
    id: 'gain',
    title: 'Gain',
    section: 'general',
    titleClassName: styles.fieldDescription,
    tooltipInfo: 'How much the token has grown from the first candle to the current moment.',
    getValue: (simulation) => `${simulation?.gain || 0}x`,
    className: (simulation) => (simulation?.gain ? styles.greenText : undefined),
  },
  {
    id: 'hasBlacklist',
    title: 'Has Blacklist',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo:
      'Indicates whether the token developer can block holder wallets. Once blacklisted, tokens in those wallets cannot be sold.',
    getValue: (simulation) => (simulation?.has_blacklist ? 'Yes' : 'No'),
    className: (simulation) => styles.fieldValue,
  },
  {
    id: 'burnLP',
    title: 'Burn LP',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo:
      'Indicates how much of the liquidity pool has been burned. The developer has no control over the burned pool. The best value is 100%.',
    getValue: (simulation) =>
      simulation?.liquidity.burned === '-'
        ? simulation?.liquidity.burned
        : `${simulation?.liquidity.burned}%`,
    className: (simulation) =>
      cn(
        styles.fieldValue,
        simulation?.liquidity.burned && +simulation?.liquidity.burned > 50 ? styles.greenText : '',
      ),
  },
]

const evmTradingFields: TokenInfoModalField[] = [
  {
    id: 'maxBuyWallet',
    title: 'Max Buy / Wallet',
    section: 'trading',
    titleClassName: styles.fieldDescription,
    tooltipInfo:
      'How many tokens can be purchased per transaction / how many tokens can be held per wallet.',
    getValue: (simulation) => (
      <>
        <Typography className={cn(styles['ff-15'], styles.defaultColor)}>
          <div>{`${getPercentage(simulation.limits?.buy.percentage ?? '0')} / 
          ${getPercentage(simulation.limits?.wallet.percentage ?? '0')}`}</div>
        </Typography>
        <Typography className={styles['ff-13']}>
          {checkSimulationValue(simulation.limits?.buy.cost_chain_currency ?? '0')
            ? formatTokenPrice(simulation.limits?.buy.cost_chain_currency ?? '0').formatted
            : '-'}{' '}
          /{' '}
          {checkSimulationValue(simulation.limits?.wallet.cost_chain_currency ?? '0')
            ? formatTokenPrice(simulation.limits?.wallet.cost_chain_currency ?? '0').formatted
            : '-'}
        </Typography>
      </>
    ),
  },
  {
    id: 'taxes',
    title: 'Tax Buy / Sell / Transfer',
    section: 'trading',
    titleClassName: styles.fieldDescription,
    tooltipInfo:
      'The amount of tax set by the developer. Means how many tokens from your transaction will be transferred to the developer when buying / selling / transferring.',
    getValue: (simulation) => (
      <Box display="flex" gap={0.75}>
        <span className={cn(styles.taxBuy, styles[getTaxesColor(simulation.taxes?.buy ?? '0')])}>
          {getPercentage(simulation.taxes?.buy ?? '0')}
        </span>
        <span className={cn(styles.taxSell, styles[getTaxesColor(simulation.taxes?.sell ?? '0')])}>
          {getPercentage(simulation.taxes?.sell ?? '0')}
        </span>
        <span
          className={cn(
            styles.taxTransfer,
            styles[getTaxesColor(simulation.taxes?.transfer ?? '0')],
          )}
        >
          {getPercentage(simulation.taxes?.transfer ?? '0')}
        </span>
      </Box>
    ),
  },
  {
    id: 'clog',
    title: 'Clog',
    section: 'trading',
    titleClassName: styles.fieldDescription,
    tooltipInfo:
      'How many tokens are stored in the smart contract waiting to be sold. The best value is 0%.',
    getValue: (simulation) => `${simulation?.clog}%`,
    className: (simulation) => styles[getClogColor(+(simulation?.clog || '0'))],
  },
]

const evmAuditFields: TokenInfoModalField[] = [
  {
    id: 'honeypot',
    title: 'Honeypot',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo: 'If the token is a Honeypot, purchased tokens will not be able to be sold.',
    getValue: (simulation) => (simulation?.honeypot ? 'Yes' : 'No'),
    className: (simulation) => (!simulation?.honeypot ? styles.greenText : styles.redText),
  },
  {
    id: 'contractVerified',
    title: 'Contract Verified',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo:
      'If yes, it means that the source code of the contract is published and can be analyzed.',
    getValue: (simulation) => (simulation.tradeable ? 'Yes' : 'No'),
    className: (simulation) => (simulation?.tradeable ? styles.greenText : styles.redText),
  },
  {
    id: 'renounced',
    title: 'Renounced',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo:
      "When a smart contract is renounced, the developer can't call functions on it. It's safe when it's renounced.",
    getValue: (simulation) => (simulation?.renounced ? 'Yes' : 'No'),
    className: (simulation) => (simulation?.renounced ? styles.greenText : styles.redText),
  },
]

const solanaAuditFields: TokenInfoModalField[] = [
  {
    id: 'mintAuth',
    title: 'Mint Authority',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo: 'Does the token have a mint authority? If yes, the developer can mint new tokens.',
    getValue: (simulation) => (simulation?.mint_auth ? 'Enabled' : 'Disabled'),
    className: (simulation) => (simulation?.mint_auth ? styles.redText : styles.greenText),
  },
  {
    id: 'freezeAuth',
    title: 'Freeze Authority',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo:
      'Does the token have a freeze authority? If yes, the developer can freeze contract.',
    getValue: (simulation) => (simulation?.freeze_auth ? 'Enabled' : 'Disabled'),
    className: (simulation) => (simulation?.freeze_auth ? styles.redText : styles.greenText),
  },
  {
    id: 'burnLP',
    title: 'LP Burned',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo:
      'Indicates how much of the liquidity pool has been burned. The developer has no control over the burned pool. The best value is 100%.',
    getValue: (simulation) =>
      simulation?.liquidity.burned === '-' ? '-' : `${simulation?.liquidity.burned || 0}%`,
    className: (simulation) =>
      Number(simulation?.liquidity?.burned || '0') > 50 ? styles.greenText : undefined,
  },
  {
    id: 'top10Holders',
    title: 'Top 10 Holders',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo: 'Cumulative percentage of total supply held by the 10 largest holders.',
    getValue: (simulation) => simulation?.top10Holders || '-',
    className: (simulation) =>
      simulation?.top10Holders && +Number(simulation?.top10Holders) > 40
        ? styles.redText
        : styles.greenText,
  },
  {
    id: 'dataMutable',
    title: 'Token Data Mutable',
    section: 'audit',
    titleClassName: styles.fieldDescription,
    tooltipInfo: 'Indicates whether the token data is mutable.',
    getValue: (simulation) => (simulation?.data_mutable ? 'Yes' : 'No'),
    className: (simulation) => (simulation?.data_mutable ? styles.redText : styles.greenText),
  },
]

// TODO: Refactor this to use univarsal config (the way it works for token info from the header)
export const getModalFieldsByChain = (chainType: 'evm' | 'solana') => {
  if (chainType === 'evm') {
    return {
      generalFields,
      tradingFields: evmTradingFields,
      auditFields: evmAuditFields,
    }
  }
  return {
    generalFields: generalFields.filter(
      (field) => field.id !== 'hasBlacklist' && field.id !== 'burnLP',
    ),
    tradingFields: [],
    auditFields: solanaAuditFields,
  }
}
