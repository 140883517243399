import { FC, useMemo } from 'react'

import { Grid } from '@mui/material'

import { Accordion, Typography } from '@/libs/common'
import { EBuyMode } from '@/libs/enums/buy-mode.enum'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { TBuyTemplate } from '@/libs/types/template'
import { useAppSelector } from '@/store'

import { TemplateSettingsElement } from './libs/components/template-settings-element'
import styles from './styles.module.scss'

// TODO: Get all the values from the props
type TProps = {
  template: TBuyTemplate
  onChange?: (value: boolean) => void
  expanded?: boolean
}

const BuyTemplateSettingsList: FC<TProps> = ({ onChange, template, expanded }) => {
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const buyMode = template.setup.operation.setup.buy_mode

  const wallets = useMemo(() => {
    if (!userWallets) return '-'

    const walletsIndexes: number[] = []
    template.setup.wallets.forEach((wallet) => {
      const userWalletIndex = userWallets.findIndex((item) => item.address === wallet.public_key)
      if (userWalletIndex !== -1) {
        walletsIndexes.push(userWalletIndex + 1)
      }
    })
    return `${walletsIndexes.join('/')} (${walletsIndexes.length})`
  }, [template])

  const buyPriority = useMemo(() => {
    if (currentChain.features?.usePriorityAmount) {
      return template.setup.operation.priority_amount
    }

    return currentChain.features?.noPriorityGas
      ? template.setup.operation.gas!.buy.gas
      : template.setup.operation.gas!.buy.miner_tip
  }, [currentChain, template])

  return (
    <Accordion
      className={styles.accordion}
      titleClassName={styles.accordionTitle}
      title="Template Settings"
      withDivider
      mainColorArrow
      onChange={onChange}
      expanded={expanded}
    >
      <Grid container rowGap={2} flexDirection="column">
        <Grid container rowGap={1.5} flexDirection="column" className={styles.settingsBlockWrapper}>
          <Typography variant="body2" textColor="white">
            Buy
          </Typography>
          <div className={styles.settingsBlock}>
            <>
              {!!currentChain.features?.buyPriority && (
                <TemplateSettingsElement
                  label={getChainSensitiveLabel('buyPriority')}
                  value={`${buyPriority} ${currentChain.priorityCurrency}`}
                />
              )}
              {!!currentChain.features?.bribeAmount && (
                <TemplateSettingsElement
                  label={getChainSensitiveLabel('bribeAmount')}
                  value={`${template.setup.operation.bribe_amount} ${currentChain.priorityCurrency}`}
                />
              )}
              {!!currentChain.features?.approvePriority && template.setup.operation.gas && (
                <TemplateSettingsElement
                  label={getChainSensitiveLabel('approvePriority')}
                  value={`${currentChain.features?.noPriorityGas ? template.setup.operation.gas.approve.gas : template.setup.operation.gas.approve.miner_tip}  ${currentChain.priorityCurrency}`}
                />
              )}
            </>
            {!!currentChain.features?.maxTxOrFail && (
              <TemplateSettingsElement
                label="Max Tx or fail"
                value={buyMode === EBuyMode.MAX_TRANSACTION}
              />
            )}
            <TemplateSettingsElement
              label="Exact % / Tokens or fail"
              value={
                buyMode === EBuyMode.MIN_TOKENS
                  ? template.setup.operation.setup.buy_tokens
                  : buyMode === EBuyMode.MIN_PERCENT
                    ? `${template.setup.operation.setup.supply_percentage}%`
                    : '-'
              }
            />
            {!currentChain.features?.noPrivateTx && (
              <TemplateSettingsElement
                label="Anti-Mev"
                value={template.setup.operation.private_tx}
              />
            )}

            <TemplateSettingsElement
              label="Degen Chad Mode"
              value={!template.setup.operation.shields.anti_honeypot}
            />
            <TemplateSettingsElement
              label="Slippage"
              value={`${template.setup.operation.setup.slippage}%`}
            />
            <TemplateSettingsElement label="Wallets selected" value={wallets} />
          </div>
        </Grid>
        <Grid container rowGap={1.5} flexDirection="column" className={styles.settingsBlockWrapper}>
          <Typography variant="body2" textColor="white">
            Safety
          </Typography>
          <div className={styles.settingsBlock}>
            {!!currentChain.features?.maxBuyTaxForTemplates && (
              <TemplateSettingsElement
                label="Max Buy Tax"
                value={
                  template.setup.operation.shields.buy_tax
                    ? `${template.setup.operation.shields.buy_tax}%`
                    : '-'
                }
              />
            )}
            {!!currentChain.features?.maxSellTaxForTemplates && (
              <TemplateSettingsElement
                label="Max Sell Tax"
                value={
                  template.setup.operation.shields.sell_tax
                    ? `${template.setup.operation.shields.sell_tax}%`
                    : '-'
                }
              />
            )}
            <TemplateSettingsElement
              label="Min Liquidity"
              value={
                template.setup.operation.shields.minimum_liquidity
                  ? `${template.setup.operation.shields.minimum_liquidity}  ${currentChain.chainSymbol}`
                  : '-'
              }
            />
            <TemplateSettingsElement
              label="Max Liquidity"
              value={
                template.setup.operation.shields.maximum_liquidity
                  ? `${template.setup.operation.shields.maximum_liquidity} ${currentChain.chainSymbol}`
                  : '-'
              }
            />
            <TemplateSettingsElement
              label="Max Marketcap"
              value={
                template.setup.operation.shields.maximum_market_cap
                  ? `$${template.setup.operation.shields.maximum_market_cap}`
                  : '-'
              }
            />
            {!currentChain.features?.noAntiDuplicate && (
              <TemplateSettingsElement
                label="Anti Duplicate"
                value={!!template.setup.operation.shields.anti_duplicate}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Accordion>
  )
}

export { BuyTemplateSettingsList }
