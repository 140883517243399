import { useEffect, useRef, useState } from 'react'

import { CandlestickData, HistogramData, IChartApi, Time, createChart } from 'lightweight-charts'

import { RESOLUTION_MAP } from '../../../configs'
import { formatMCap, formatPrice } from '../../../helpers'
import { Datafeed } from '../utils/datafeed'

type ChartData = {
  candles: CandlestickData<Time>[]
  volumes: HistogramData<Time>[]
}

export const useChartData = (
  containerRef: React.RefObject<HTMLDivElement>,
  tokenAddress: string,
  intervals: {
    resolution: keyof typeof RESOLUTION_MAP
    from: number
    to: number
  },
  displayType: 'price' | 'mcap',
) => {
  const [data, setData] = useState<ChartData>({ candles: [], volumes: [] })
  const chartRef = useRef<IChartApi | null>(null)
  const adapterRef = useRef<Datafeed | null>(null)
  const candleSeriesRef = useRef<any>(null)
  const volumeSeriesRef = useRef<any>(null)

  useEffect(() => {
    if (!containerRef.current) return

    if (chartRef.current) {
      chartRef.current.remove()
      chartRef.current = null
    }

    chartRef.current = createChart(containerRef.current, {
      height: 500,
      layout: {
        background: { color: '#101010' },
        textColor: '#D9D9D9',
      },
      grid: {
        vertLines: { color: '#363c4e' },
        horzLines: { color: '#363c4e' },
      },
      timeScale: {
        borderColor: '#485c7b',
        timeVisible: true,
        secondsVisible: false,
      },
      rightPriceScale: {
        visible: true,
        textColor: '#D9D9D9',
        borderColor: '#485c7b',
        scaleMargins: {
          top: 0.1,
          bottom: 0.2,
        },
      },
      overlayPriceScales: {
        scaleMargins: {
          top: 0.8,
          bottom: 0,
        },
      },
    })

    candleSeriesRef.current = chartRef.current.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
      priceScaleId: 'right',
      visible: true,
      priceFormat: {
        type: 'custom',
        formatter: (price: number) => {
          return displayType === 'price' ? formatPrice(price) : formatMCap(price)
        },
        minMove: 0.000001,
      },
    })

    volumeSeriesRef.current = chartRef.current.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: 'overlay',
    })

    adapterRef.current = new Datafeed(chartRef.current)

    return () => {
      adapterRef.current?.disconnect()
      chartRef.current?.remove()
      chartRef.current = null
    }
  }, [displayType])

  useEffect(() => {
    if (!chartRef.current || !adapterRef.current) return

    const loadData = async () => {
      try {
        await adapterRef.current?.connectWebsocket()
        const chartData = await adapterRef.current?.fetchHistoricalData(
          tokenAddress,
          intervals.resolution,
          intervals.from,
          intervals.to,
        )

        if (chartData && candleSeriesRef.current && volumeSeriesRef.current) {
          const newData = {
            candles: chartData.map((d) => d.candle),
            volumes: chartData.map((d) => d.volume),
          }

          setData(newData)
          candleSeriesRef.current.setData(newData.candles)
          volumeSeriesRef.current.setData(newData.volumes)

          adapterRef.current?.subscribeToRealtimeData(
            intervals.resolution,
            candleSeriesRef.current,
            volumeSeriesRef.current,
          )
        }
      } catch (error) {
        console.error('Failed to load chart data:', error)
      }
    }

    loadData()
  }, [tokenAddress, intervals, displayType])

  return data
}
