import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { Grid } from '@mui/material'
import cls from 'classnames'

import { PriorityCurrency } from '@/components/priority-currency'
import { SelectWallet } from '@/components/select-wallet'
import {
  Button,
  ButtonGroupRadio,
  ButtonGroupRadioButton,
  Icon,
  Input,
  InputWithRadioGroup,
  Typography,
} from '@/libs/common'
import { EndAdornment } from '@/libs/common/input/components/end-adornment'
import { OptionalInput } from '@/libs/common/optional-input'
import { PercentsInput } from '@/libs/common/percents-input'
import { SwitchInline } from '@/libs/common/switch-inline'
import { IconName } from '@/libs/enums'
import { TSelectOption } from '@/libs/types/select-option.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const limitOptions: TSelectOption<number>[] = [
  {
    value: -10,
    label: '-10%',
  },
  {
    value: -20,
    label: '-20%',
  },
  {
    value: -30,
    label: '-30%',
  },
  {
    value: -40,
    label: '-40%',
  },
]

const defaultValues = {
  selectedWallets: [],
  slippage: 0,
  sellPriority: '',
  privateTransaction: false,
  dip: '',
  marketcap: '',
  price: '',
  triggerPricePercent: '',
  expiration: '',
  spend: '',
  receive: '',
}

const spendOptions: TSelectOption<number>[] = [
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.5,
    label: '50%',
  },
  {
    value: 0.75,
    label: '75%',
  },
  {
    value: 1,
    label: '100%',
  },
]

const EditLimitSell = () => {
  const { token } = useParams()
  const [footerTab, setFooterTab] = useState<string>('market')
  const { control, handleSubmit } = useForm({ defaultValues })
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const { iconName, chainSymbol } = currentChain

  const handleFooterTabChange = (_: React.BaseSyntheticEvent, newValue: string) => {
    setFooterTab(newValue)
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    // Send data to backend
    return data
  }
  return (
    <div className={styles.container}>
      <Typography variant="h1" textColor="light-grey" textAlign="center">
        Edit {token} Limit Sell settings
      </Typography>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowGap={3}>
          <Controller
            name="selectedWallets"
            control={control}
            render={({ field: { ref, value, ...field } }) => (
              <SelectWallet
                tooltipInfo="Select which wallets will be used to purchase token."
                label="Select wallets"
                isMulti
                wallet={value}
                {...field}
              />
            )}
          />
          <Controller
            name="slippage"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <PercentsInput
                label="Slippage"
                onOptionSelect={(value) => field.onChange(value)}
                tooltipInfo="The allowable change in token price that can be tolerated during transaction execution."
                placeholder="X"
                {...field}
              />
            )}
          />
          <Controller
            name="sellPriority"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <OptionalInput
                placeholder="Enter gwei amount"
                isNumeric
                label="Sell Priority"
                tooltipInfo={`Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ${currentChain.chainSymbol}.`}
                endAdornment={<PriorityCurrency />}
                onAutoChange={() => {
                  //TO DO: added auto switch
                }}
                {...field}
              />
            )}
          />
          {!currentChain.features?.noPrivateTx && (
            <Controller
              name="privateTransaction"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <SwitchInline
                  label="Anti-Mev"
                  tooltipInfo="Others won't be able to see your transaction until it's complete. Protects from front-running."
                  {...field}
                />
              )}
            />
          )}

          <Grid container rowGap={2} flexDirection="column">
            <ButtonGroupRadio
              onChange={handleFooterTabChange}
              value={footerTab}
              exclusive
              className={cls(styles.group, styles.bgGrey)}
            >
              <ButtonGroupRadioButton value="market">Market</ButtonGroupRadioButton>
              <ButtonGroupRadioButton value="limit">Limit</ButtonGroupRadioButton>
            </ButtonGroupRadio>
            {footerTab === 'limit' && (
              <Grid container rowGap={2} flexDirection="column" className={styles.limitBlock}>
                <div>
                  <Typography variant="body2" textColor="light-grey">
                    Trigger price
                  </Typography>
                  <div className={styles.inputWrapper}>
                    <Controller
                      name="dip"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={cls(styles.input, styles.first)}
                          placeholder="Enter dip %"
                          endAdornment={<EndAdornment label="%" />}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="marketcap"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={styles.input}
                          placeholder="Enter marketcap"
                          endAdornment={<EndAdornment label="MC" />}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="price"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Input
                          className={cls(styles.input, styles.last)}
                          placeholder="Enter price"
                          endAdornment={<EndAdornment label="$" />}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="triggerPricePercent"
                    control={control}
                    render={({ field: { ref, onChange, ...field } }) => (
                      <ButtonGroupRadio
                        className={styles.radioGroup}
                        onChange={(_, value: string) => onChange(value)}
                        exclusive
                        {...field}
                      >
                        {limitOptions.map((option) => (
                          <ButtonGroupRadioButton
                            value={option.value}
                            className={styles.radioButton}
                            key={option.value}
                          >
                            {option.label}
                          </ButtonGroupRadioButton>
                        ))}
                      </ButtonGroupRadio>
                    )}
                  />
                </div>

                <Controller
                  name="expiration"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <OptionalInput
                      label="Expiration"
                      tooltipInfo="Enter the number of hours the limit order is valid. After this time expires, it will be removed."
                      className={styles.input}
                      placeholder="Enter the position expiration time in hours"
                      adornmentText="Hours"
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <div className={styles.footer}>
            <div className={styles.arrow}>
              <Icon name={IconName.ARROW_DOWN_GREY} />
            </div>

            <div className={styles.container}>
              <div className={styles.spend}>
                <Controller
                  name="spend"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <InputWithRadioGroup
                      label="Sell"
                      placeholder="Enter amount to sell"
                      isNumeric
                      className={styles.input}
                      onOptionSelect={(value) => field.onChange(value)}
                      endAdornment={
                        <EndAdornment label={chainSymbol} icon={(IconName as any)[iconName]} />
                      }
                      radioGroupName="amount"
                      options={spendOptions}
                      {...field}
                    />
                  )}
                />
                <div className={styles.available}>
                  <Typography variant="body2" className={styles.param}>
                    Available:
                  </Typography>
                  <Typography variant="body2" className={styles.value}>
                    13.003454 ETH
                  </Typography>
                </div>
              </div>

              <div className={styles.union}>
                <Controller
                  name="receive"
                  control={control}
                  render={({ field: { ref, ...field } }) => (
                    <Input
                      label="Receive"
                      placeholder="Enter amount to receive"
                      isNumeric
                      className={styles.input}
                      endAdornment={<EndAdornment label="PEPE" icon={IconName.PEPE} />}
                      {...field}
                    />
                  )}
                />
                <div className={cls(styles.infoContainer)}>
                  <div className={styles.info}>
                    <Typography variant="body2" className={styles.param}>
                      DEX:
                    </Typography>
                    <Typography variant="body2" className={styles.value}>
                      UNI V2
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <Button type="submit">Save</Button>
          </div>
        </Grid>
      </form>
    </div>
  )
}

export { EditLimitSell }
