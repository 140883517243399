import { Grid, Stack } from '@mui/material'

import { BackButton, ButtonLink, Icon, Input, ListButton, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const mockData = [
  { id: 1, title: 'BlazingBot General' },
  { id: 2, title: 'BlazingBot General' },
  { id: 3, title: 'BlazingBot General' },
  { id: 4, title: 'BlazingBot General' },
  { id: 5, title: 'BlazingBot General' },
]

const AddNewScrapeGroup = () => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  return (
    <section className={styles.section}>
      <Stack className={styles.stack} useFlexGap>
        <Input
          isTransparent
          label="Select group to scrape from"
          placeholder="Search group name"
          startAdornment={<Icon name={IconName.SEARCH} />}
          className={styles.input}
        />

        <div className={styles.list}>
          {mockData.map((item) => (
            <ButtonLink
              className={styles.listButton}
              key={item.id}
              to={`${AppRoute.DASHBOARD}/${AppMode.PRO}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.ADD_NEW_SCRAPE}`}
            >
              <ListButton>
                <Grid container alignItems="center" gap={1.5}>
                  <Icon name={IconName.ETHEREUM} />

                  <Typography variant="body2" textColor="light-grey">
                    {item.title}
                  </Typography>
                </Grid>
              </ListButton>
            </ButtonLink>
          ))}
        </div>
      </Stack>
    </section>
  )
}

export { AddNewScrapeGroup }
