import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { DynamicWagmiConnector } from '@dynamic-labs/wagmi-connector'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { http } from 'viem'
import { base, bsc, mainnet } from 'viem/chains'
import { WagmiProvider, createConfig } from 'wagmi'

import { App } from '@/components/app'
import { CustomDynamicProvider } from '@/components/custom-dynamic-provider'

import './index.scss'
import { store } from './store'
import { initAnalytics } from './utils/analytics'

initAnalytics()

const root = createRoot(document.getElementById('root') as HTMLElement)

const config = createConfig({
  chains: [mainnet, base, bsc],
  multiInjectedProviderDiscovery: false,
  transports: {
    [mainnet.id]: http(),
    [base.id]: http(),
    [bsc.id]: http(),
  },
})

const queryClient = new QueryClient()

root.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <CustomDynamicProvider>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <DynamicWagmiConnector>
              <App />
            </DynamicWagmiConnector>
          </QueryClientProvider>
        </WagmiProvider>
      </CustomDynamicProvider>
    </DndProvider>
  </Provider>,
)
