import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { Grid } from '@mui/material'

import { getSetup, setSetup } from '@/api/user'
import { PriorityCurrency } from '@/components/priority-currency'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button } from '@/libs/common'
import { OptionalInput } from '@/libs/common/optional-input'
import { AppRoute } from '@/libs/enums'
import { extractErrorDescription } from '@/libs/helper/extractErrorDescription'
import { getChainSensitiveLabel } from '@/libs/helper/getChainSensitiveLabel'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUserPrioritySettings } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const defaultValues = {
  approve_priority: '',
  buy_priority: '',
  sell_priority: '',
  bribe_amount: '',
}

const SetupPriorities = () => {
  const tokenAddress = useAppSelector((state) => state.chain.currentToken?.token.address)
  const navigate = useCustomNavigate()
  const userData = useAppSelector((state) => state.user.userData)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const networkId = useAppSelector((state) => state.chain.currentChain.indexerChainId)

  const { isBottomTabsVisible } = useWindowDimensions()
  const dispatch = useAppDispatch()
  const { setModalProps } = useModal()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues })

  const fetchSetupValues = async () => {
    if (!userData) return

    try {
      const { data } = await getSetup(userData.user_id)
      const values = {
        approve_priority: data.data.approve_priority,
        buy_priority: data.data.buy_priority,
        sell_priority: data.data.sell_priority,
        bribe_amount: data.data.bribe_amount,
      }
      Object.keys(values).forEach((key: any) => {
        const newValue = (values as any)[key]
        if (newValue) setValue(key, newValue.toString())
      })
    } catch (err) {
      extractErrorDescription(err)
    }
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    if (!userData) return
    setSetup(userData.user_id, { ...data, blockchain: networkId })
    dispatch(setUserPrioritySettings(data))
    navigate({
      isDashboard: true,
      path: `${isBottomTabsVisible ? `/${AppRoute.MODAL}/${AppRoute.BUY_MODAL}` : ''}`,
      searchParams: {
        token: tokenAddress || '',
      },
    })
  }

  useEffect(() => {
    setModalProps({
      title: getChainSensitiveLabel('setupDefaultPriorities'),
      withBackButton: isBottomTabsVisible,
    })
  }, [])

  useEffect(() => {
    fetchSetupValues()
  }, [userData])

  return (
    <Grid spacing={3} gap={2}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" gap={2}>
          <Grid container rowGap={2}>
            {!!currentChain.features?.buyPriority && (
              <Controller
                name="buy_priority"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    className={styles.input}
                    label={`Default ${getChainSensitiveLabel('buyPriority')}`}
                    placeholder="Enter gwei amount"
                    isNumeric
                    tooltipInfo={`An extra fee that you can add to to speed up the transaction execution. 1 GWEI = 0.000000001 ${currentChain.chainSymbol}.`}
                    endAdornment={<PriorityCurrency />}
                    error={!!errors.buy_priority?.message}
                    {...field}
                  />
                )}
              />
            )}
            {!!currentChain.features?.sellPriority && (
              <Controller
                name="sell_priority"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    className={styles.input}
                    label={`Default ${getChainSensitiveLabel('sellPriority')}`}
                    placeholder="Enter gwei amount"
                    isNumeric
                    tooltipInfo={`Enter the extra Gwei amount that will be used to prioritize your transaction in the network. 1 Gwei is equal to 0.000000001 ${currentChain.chainSymbol}.`}
                    endAdornment={<PriorityCurrency />}
                    error={!!errors.sell_priority?.message}
                    {...field}
                  />
                )}
              />
            )}
            {!!currentChain.features?.approvePriority && (
              <Controller
                name="approve_priority"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    className={styles.input}
                    label={`Default ${getChainSensitiveLabel('approvePriority')}`}
                    placeholder="Enter gwei amount"
                    isNumeric
                    tooltipInfo={`An extra fee that can be added to speed up the new token approve for you wallet. 1 GWEI = 0.000000001 ${currentChain.chainSymbol}.`}
                    endAdornment={<PriorityCurrency />}
                    error={!!errors.approve_priority?.message}
                    {...field}
                  />
                )}
              />
            )}
            {!!currentChain.features?.bribeAmount && (
              <Controller
                name="bribe_amount"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <OptionalInput
                    className={styles.input}
                    label="Default Bribe Amount"
                    placeholder="Enter gwei amount"
                    isNumeric
                    tooltipInfo="An extra fee that you can add to increase the likelihood of your transaction being included in a block."
                    endAdornment={<PriorityCurrency />}
                    error={!!errors.bribe_amount?.message}
                    {...field}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>

        <Button type="submit" className={styles.button}>
          Save
        </Button>
      </form>
    </Grid>
  )
}

export { SetupPriorities }
