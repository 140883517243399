import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { UserFormItem } from '@/components/user-settings/libs/components/user-form-item/user-form-item'
import { userNameValidation } from '@/libs/validations/user'

import { updateUsername } from './helpers/helpers'

interface UserNameFormProps {
  username?: string
  usernameSet?: boolean
  onSuccess?: () => void
  className?: string
}

const validation = yup.object({
  userName: userNameValidation,
})

export const UserNameForm: FC<UserNameFormProps> = ({
  username = '',
  usernameSet = false,
  onSuccess,
  className,
}) => {
  const formMethods = useForm({
    defaultValues: {
      userName: username,
    },
    resolver: yupResolver(validation),
    mode: 'all',
  })

  const saveData = async () => {
    const value = formMethods.getValues().userName || ''
    await updateUsername(value, onSuccess)
  }

  return (
    <FormProvider {...formMethods}>
      <form className={className}>
        <UserFormItem
          title="Display Name"
          currentValue={username}
          name="userName"
          subRule={
            usernameSet
              ? 'You have already changed your username.'
              : 'You have one chance to change your username. Be wise!'
          }
          placeholder="Enter name"
          disabled={usernameSet}
          onSubmit={saveData}
        />
      </form>
    </FormProvider>
  )
}
