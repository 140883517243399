import { useEffect, useRef } from 'react'

import cls from 'classnames'

import { FavoritesTable } from '@/components/favorites-table'
import { HoldingsTable } from '@/components/holdings-table'
import { PairStats } from '@/components/pair-stats'
import { NoTokenSelected } from '@/components/search-with-token-info/libs/components/no-token-selected'
import { SelectedTokenInfo } from '@/components/selected-token-info'
import useComponentWidth from '@/hooks/useComponentWidth'
import { useCurrentTokenInfoLoading } from '@/hooks/useCurrentTokenInfoLoading'
import { InputSearch, Tabs } from '@/libs/common'
import { SpinnerSize } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { setIsNavigateAvailable } from '@/store/slices/app.slice'
import { isInTelegramBrowser } from '@/utils/is-in-telegram-browser'

import { OrdersHistoryTable } from '../orders-history-table'
import { Spinner } from '../spinner'
import { TradingViewNew } from '../trading-view-new'
import { TelegramTradingView } from '../trading-view-new/libs/telegram-trading-view'
import { BuySellBlock } from './libs/buy-sell-block'
import { TokenCard } from './libs/token-card'
import { TransactionsContainer } from './libs/transactions-container'
import styles from './styles.module.scss'

const DashboardComponentLite = () => {
  const dispatch = useAppDispatch()
  const selectedToken = useAppSelector((state) => state.chain.currentToken)
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const refInfo = useRef<HTMLInputElement>(null)

  const [componentWidth] = useComponentWidth(refInfo)

  const isLoading = useCurrentTokenInfoLoading()

  useEffect(() => {
    dispatch(setIsNavigateAvailable(true))
  }, [])

  const tabs = [
    {
      label: 'Transactions',
      content: (
        <div className={styles.tabContainer}>
          <TransactionsContainer />
        </div>
      ),
    },
    {
      label: 'My positions',
      content: <HoldingsTable />,
    },
    {
      label: 'Orders History',
      content: <OrdersHistoryTable />,
    },
    {
      label: 'Favorites',
      content: <FavoritesTable />,
    },
    // {
    //   label: 'History',
    //   content: <LiteHistoryTable />,
    // },
  ]

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.searchBlock}>
          <InputSearch placeholder="Enter token name or address" />
        </div>

        <div className={cls(styles.block, styles.main)}>
          <div className={styles.tokenInfoBlock} ref={refInfo}>
            {selectedTokenAddress ? (
              isLoading ? (
                <Spinner size={SpinnerSize.SMALL} centered />
              ) : (
                selectedToken && <SelectedTokenInfo selectedToken={selectedToken} />
              )
            ) : (
              <NoTokenSelected />
            )}
          </div>
          {isInTelegramBrowser() ? (
            <TelegramTradingView />
          ) : (
            <TradingViewNew componentWidth={componentWidth} />
          )}

          <div className={styles.tabWrapper}>
            <Tabs
              tabs={tabs}
              variant={componentWidth < 500 ? 'scrollable' : 'standard'}
              scrollButtons={componentWidth < 500}
              allowScrollButtonsMobile={componentWidth < 500}
            />
          </div>
        </div>
        <div className={styles.sidebar}>
          <TokenCard selectedToken={selectedToken} />
          <PairStats />
          <BuySellBlock />
        </div>
      </div>
    </div>
  )
}

export { DashboardComponentLite }
