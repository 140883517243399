import { useCallback, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { PublicKey } from '@solana/web3.js'
import bs58 from 'bs58'
import nacl from 'tweetnacl'

import { getNonce, getStateOfUser, signIn } from '@/api/user'
import { CustomToast } from '@/components/custom-toast'
import { useCustomNavigate } from '@/hooks/useCustomNavigate'
import { useIsMobile } from '@/hooks/useIsMobile'
import { chainsConfig } from '@/libs/configs/chains.config'
import { AppRoute } from '@/libs/enums'
import { useAppDispatch } from '@/store'
import { setCurrentChain } from '@/store/slices/chain.slice'
import { fetchUserBuyTemplates, fetchUserById } from '@/store/slices/user.slice'

import { phantomDeeplinksApi } from './phantom-deeplinks-api'
import { phantomStorage } from './phantom-storage'

function getRedirectLink(params: string) {
  return `${window.location.origin}/${AppRoute.PHANTOM_DEEPLINKS}?${params}`
}

async function getMessage() {
  const { data } = await getNonce()
  const messageNonce = data.data.nonce
  const message = `By Signing, You Agree To Our Terms Of Use And Privacy Policy: ${messageNonce}`

  return { message, messageNonce }
}

export function usePhantomDeeplinks() {
  const dispatch = useAppDispatch()
  const navigate = useCustomNavigate()

  const isMobile = useIsMobile()
  const [dappKeyPair] = useState(nacl.box.keyPair())

  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()

  const clearUrl = useCallback(() => {
    // Clear the URL without reloading the page
    const baseUrl = window.location.origin + window.location.pathname
    window.history.replaceState({}, document.title, baseUrl)
  }, [])

  const handleError = useCallback((error: string) => {
    CustomToast('error', error)

    phantomStorage.clear()
    clearUrl()
  }, [])

  const connect = useCallback(() => {
    phantomStorage.clear()
    clearUrl()

    const pk = bs58.encode(dappKeyPair.publicKey)
    const sk = bs58.encode(dappKeyPair.secretKey)

    phantomStorage.setItem('dapp_key_pair_public_key', pk)
    phantomStorage.setItem('dapp_key_pair_secret_key', sk)

    phantomDeeplinksApi.connect({
      app_url: window.location.origin,
      dapp_encryption_public_key: pk,
      redirect_link: getRedirectLink('onConnect=true'),
    })
  }, [dappKeyPair, pathname, clearUrl])

  const disconnect = useCallback(() => {
    const dappKeyPairPublicKey = phantomStorage.getItem('dapp_key_pair_public_key')
    const session = phantomStorage.getItem('session')
    const sharedSecretDapp = phantomStorage.getItem('shared_secret_dapp')

    if (!session || !sharedSecretDapp || !dappKeyPairPublicKey) {
      handleError('Could not disconnect from Phantom. Please try again.')
      return
    }

    phantomDeeplinksApi.disconnect(
      {
        dapp_encryption_public_key: bs58.encode(dappKeyPairPublicKey),
        redirect_link: getRedirectLink('onDisconnect=true'),
        session,
      },
      sharedSecretDapp,
    )
  }, [pathname])

  const sign = useCallback(async () => {
    try {
      const address = phantomStorage.getItem('address')!
      const signature = phantomStorage.getItem('signature')!
      const messageNonce = phantomStorage.getItem('message_nonce')!

      dispatch(setCurrentChain(chainsConfig.find((chain) => chain.id === 8)!))

      const userState = await getStateOfUser(address)
      const isUserExists = userState.data.data.exists

      if (isUserExists) {
        try {
          const res = await signIn({
            blockchain: 8,
            signatory: address,
            signature,
            nonce: messageNonce,
          })
          const { token, refresh_token, id } = res.data.data
          localStorage.token = token
          localStorage.refreshToken = refresh_token

          await dispatch(fetchUserById(id)).unwrap()
          dispatch(fetchUserBuyTemplates())

          navigate({
            path: `/${AppRoute.ADD_CHAIN}`,
            isDashboard: !localStorage.code,
            searchParams: localStorage.code
              ? { code: localStorage.code }
              : { token: searchParams.get('token') },
          })
        } catch (e: any) {
          const errCode = e?.response?.data?.data?.code
          if (errCode === 'Q9S7WRQT81') {
            navigate({
              path: `/${AppRoute.ENTER_CODE}`,
              searchParams: { wallet: address },
            })
          } else {
            navigate({ delta: -1 })
          }
        }
      } else {
        localStorage.signData = JSON.stringify({
          blockchain: 8,
          signatory: address,
          signature,
          nonce: messageNonce,
        })
        localStorage.account = JSON.stringify({
          signatory: address,
          signature,
          nonce: messageNonce,
        })
        navigate({ path: `/${AppRoute.ALREADY_SIGN}` })
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  const signMessage = useCallback(async () => {
    const dappKeyPairPublicKey = phantomStorage.getItem('dapp_key_pair_public_key')
    const session = phantomStorage.getItem('session')
    const sharedSecretDapp = phantomStorage.getItem('shared_secret_dapp')

    if (!dappKeyPairPublicKey || !session || !sharedSecretDapp) {
      handleError('Could not sign message. Please try again.')
      return
    }

    const { message, messageNonce } = await getMessage()

    phantomStorage.setItem('message_nonce', messageNonce)

    phantomDeeplinksApi.signMessage(
      {
        dapp_encryption_public_key: bs58.encode(dappKeyPairPublicKey),
        redirect_link: getRedirectLink('onSignMessage=true'),
        message,
        session,
      },
      sharedSecretDapp,
    )
  }, [pathname])

  useEffect(() => {
    if (!isMobile) {
      return
    }

    const onConnect = searchParams.get('onConnect')
    const onDisconnect = searchParams.get('onDisconnect')
    const onSignMessage = searchParams.get('onSignMessage')

    if (onConnect) {
      phantomDeeplinksApi.onConnect({
        searchParams,
        onApproved: (data) => {
          const dappKeyPairSecretKey = phantomStorage.getItem('dapp_key_pair_secret_key')

          if (!dappKeyPairSecretKey) {
            handleError('Could not connect to Phantom. Please try again.')
            return
          }

          const sharedSecretDapp = nacl.box.before(
            bs58.decode(data.phantom_encryption_public_key),
            dappKeyPairSecretKey,
          )

          const { public_key, session } = phantomDeeplinksApi.decryptPayload<{
            public_key: string
            session: string
          }>(data.data, data.nonce, sharedSecretDapp)

          phantomStorage.setItem('address', new PublicKey(public_key).toString())
          phantomStorage.setItem('shared_secret_dapp', sharedSecretDapp)
          phantomStorage.setItem('session', session)

          clearUrl()
        },
        onRejected: (error) => {
          handleError(`${error.errorCode}: ${error.errorMessage}`)
        },
        onError: () => {
          handleError('Could not connect to Phantom. Incorrect parameters. Please try again.')
        },
      })
      return
    }

    if (onDisconnect) {
      phantomDeeplinksApi.onDisconnect({
        searchParams,
        onApproved: () => {
          phantomStorage.clear()
        },
        onRejected: (error) => {
          handleError(`${error.errorCode}: ${error.errorMessage}`)
        },
      })
      return
    }

    if (onSignMessage) {
      phantomDeeplinksApi.onSignMessage({
        searchParams,
        onApproved: (data) => {
          const sharedSecretDapp = phantomStorage.getItem('shared_secret_dapp')

          if (!sharedSecretDapp) {
            handleError('Could not handle onSignMessage. Shared secret dapp is not found')
            return
          }

          phantomStorage.removeItem('dapp_key_pair_secret_key')

          const { signature } = phantomDeeplinksApi.decryptPayload<{
            signature: string
          }>(data.data, data.nonce, sharedSecretDapp)

          phantomStorage.setItem('signature', signature)

          sign()
        },
        onRejected: (error) => {
          handleError(`${error.errorCode}: ${error.errorMessage}`)
        },
        onError: () => {
          handleError('Could not sign message. Incorrect parameters. Please try again.')
        },
      })
      return
    }
  }, [searchParams, clearUrl, isMobile])

  return {
    connect,
    signMessage,
    disconnect,
  }
}
