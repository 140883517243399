import * as yup from 'yup'

const passwordValidation = yup
  .string()
  .required('The field is required')
  .min(12, 'Password must be at least 12 characters')
  .max(32, 'Password must be less than 32 characters')
  .trim()

const userNameValidation = yup
  .string()
  .trim()
  .matches(/^[^?& ]+$/, 'Username cannot contain spaces or special characters')
  .min(3)

export { passwordValidation, userNameValidation }
