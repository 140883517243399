import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import cn from 'classnames'
import * as yup from 'yup'

import { PriorityCurrency } from '@/components/priority-currency'
import { SelectFees } from '@/components/select-fees'
import { SelectWallet } from '@/components/select-wallet'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useWalletFromParams } from '@/hooks/useWalletFromParams'
import { Button, InputWithRadioGroup, Tabs, Typography } from '@/libs/common'
import { AmountDropdown } from '@/libs/common/input/components/amount-dropdown'
import { OptionalInput } from '@/libs/common/optional-input'
import { MAX_TRX_DECIMALS } from '@/libs/configs/transactions.config'
import { AppRoute, ETransferMode } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { TFees } from '@/libs/types'
import { TUserWallet } from '@/libs/types/user.type'
import { stringOfNumbersValidation } from '@/libs/validations/common'
import { useModal } from '@/pages/modal-page/modal-page'
import { useAppSelector } from '@/store'

import { addressLength, spendPercentageOptions, spendValuesOptions } from './libs/constants'
import styles from './styles.module.scss'

const defaultValues = {
  amount: '',
  address: '',
  bribeAmount: '',
  selectedWallets: [] as string[],
}

type TUserWalletWithHoldingBalance = TUserWallet & { holdingBalance?: string }

type TProps = {
  transferMode: ETransferMode
}

const TransferFromWallet: FC<TProps> = ({ transferMode }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userWallets = useAppSelector((state) => state.user.userWallets)
  const defaultPriorities = useAppSelector((state) => state.user.defaultPriorities)
  const userHoldings = useAppSelector((state) => state.user.userHoldingsWebsocket?.v.t)

  const [isPercentageUnit, setIsPercentageUnit] = useState(false)
  const [activeRadioIndex, setActiveRadioIndex] = useState<number | null>(null)
  const [fees, setFees] = useState<TFees | null>(null)
  const [isDisabled, setIsDisable] = useState(true)
  const [holdingAddress, setHoldingAddress] = useState<string | null>(null)
  const [tab, setTab] = useState(0)
  const [minAvailableWalletBalance, setMinAvailableWalletBalance] = useState(0)

  const navigate = useCustomNavigate()
  const currentWallet = useWalletFromParams()
  const { setModalProps } = useModal()

  const getSelectedHolding = (currentHolding = holding) => {
    if (!currentHolding) return null
    const walletAddressLower = currentWallet?.address.toLowerCase()
    return currentHolding.w.find((item) => item.a.toLowerCase() === walletAddressLower)
  }

  const holding = useMemo(
    () => userHoldings?.find((item) => item.a === holdingAddress),
    [holdingAddress, userHoldings],
  )
  const holdingWallet = useMemo(() => getSelectedHolding(holding), [holding])

  // Set modal title
  useEffect(() => {
    let titleLabel = ''
    switch (transferMode) {
      case ETransferMode.TRANSFER:
        titleLabel = 'Transfer from'
        break
      case ETransferMode.DISPERSE:
        titleLabel = 'Disperse multi from'
        break
      case ETransferMode.COLLECT:
        titleLabel = 'Collect multi to'
        break
    }

    setModalProps({
      title: `${titleLabel} ${currentWallet?.name}`,
      titleProps: { className: styles.title },
      withBackButton: true,
    })
  }, [currentWallet, transferMode])

  // Current wallet balance (native or holding)
  const walletBalance = useMemo(() => {
    let balance = +(currentWallet?.balanceFormatted || 0)
    if (holdingWallet) {
      balance = +(holdingWallet?.b || 0)
    }
    return balance
  }, [holdingWallet, userWallets])

  const schema = yup.object({
    amount: stringOfNumbersValidation
      .required()
      .test(
        (value) =>
          +value <=
          (isPercentageUnit
            ? 100
            : transferMode === ETransferMode.COLLECT
              ? minAvailableWalletBalance
              : walletBalance),
      ),
    address: yup.string(),
  })

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema) as any,
    mode: 'all',
  })

  const amount = watch('amount')
  const externalWallet = watch('address')
  const selectedWallets = watch('selectedWallets')
  const bribeAmount = watch('bribeAmount')
  const chainCurrency = currentChain.chainSymbol

  const handleMinAvailableWalletBalance = (wallets?: TUserWalletWithHoldingBalance[]) => {
    if (!wallets) return
    setMinAvailableWalletBalance(
      wallets.reduce((acc, wallet) => {
        acc = Math.max(acc, +((holding ? wallet.holdingBalance : wallet.balanceFormatted) || 0))
        return acc
      }, 0),
    )
  }

  // Wallets that are available for selection
  const availableWallets: TUserWalletWithHoldingBalance[] | undefined = useMemo(() => {
    const excludeWalletsWithLowAmount = transferMode === ETransferMode.COLLECT
    if (!holding) {
      const walletsWithNoHoldings = userWallets?.filter(
        (el) =>
          el.address !== currentWallet?.address &&
          (!excludeWalletsWithLowAmount ||
            +(el.balanceFormatted || 0) > (isPercentageUnit ? 0 : +amount)),
      )
      return walletsWithNoHoldings
    }

    const walletsWithHoldings = userWallets?.reduce((acc, wallet) => {
      if (wallet.address === currentWallet?.address) {
        return acc
      }
      const holdingItem = holding?.w.find((holdingWallet) => holdingWallet.a === wallet.address)
      if (
        !excludeWalletsWithLowAmount ||
        (!!holdingItem && +holdingItem.b > (isPercentageUnit ? 0 : +amount))
      ) {
        acc.push({ ...wallet, holdingBalance: holdingItem?.b })
      }
      return acc
    }, [] as TUserWalletWithHoldingBalance[])

    return walletsWithHoldings
  }, [userWallets, holding, amount, transferMode, isPercentageUnit])

  // Handle min wallet balance (native or token)
  useEffect(() => {
    handleMinAvailableWalletBalance(availableWallets)
    if (selectedWallets.length !== availableWallets?.length)
      setValue(
        'selectedWallets',
        selectedWallets.filter((selectedWallet) =>
          availableWallets?.find((wallet) => wallet.address === selectedWallet),
        ),
      )
  }, [availableWallets, holding, transferMode])

  // Handle submit button state
  useEffect(() => {
    const isWalletExist =
      transferMode === ETransferMode.TRANSFER && tab === 0
        ? externalWallet.replace(/\s/g, '')
        : selectedWallets.length
    setIsDisable(
      !isWalletExist ||
        !fees ||
        !isValid ||
        !+fees.minerTip ||
        (!!currentChain.features?.bribeAmount && !+bribeAmount),
    )
  }, [tab, fees, externalWallet, selectedWallets, isValid, bribeAmount])

  // Trigger the field validation
  useEffect(() => {
    if (amount) trigger('amount')
  }, [walletBalance, isPercentageUnit])

  // Set default bribe amount
  useEffect(() => {
    if (!defaultPriorities) {
      return
    }

    if (!!currentChain.features?.bribeAmount && !bribeAmount) {
      setValue('bribeAmount', defaultPriorities.bribe_amount)
    }

    if (!fees && !!currentChain.features?.noPriorityGas) {
      setFees({ type: 'advanced', minerTip: defaultPriorities.buy_priority })
    }
  }, [defaultPriorities, currentChain.indexerChainId])

  const calculateWorth = (amount: number) => {
    return holding && holdingWallet?.b
      ? amount * (+holding.tvu / +holdingWallet.b || 0)
      : amount * (currentChain?.nativeTokenPriceInUsd || 0)
  }

  const onSubmit: SubmitHandler<typeof defaultValues> = (data) => {
    if (!fees) return

    const isPercent = transferMode === ETransferMode.COLLECT && isPercentageUnit

    const wallets =
      transferMode === ETransferMode.TRANSFER && tab === 0 ? [externalWallet] : data.selectedWallets

    const payload = {
      type: transferMode,
      token: holding,
      wallets,
      amount: convertScientificNotationNumber(
        transferMode === ETransferMode.COLLECT ? totalAmount : totalAmount / wallets.length,
        MAX_TRX_DECIMALS,
      ),
      minerTip: fees.minerTip,
      percentage: isPercent ? data.amount : 0,
      bribeAmount: data.bribeAmount,
    }

    navigate({
      isDashboard: true,
      path: `${AppRoute.MODAL}/${AppRoute.CONFIRM_TRANSFER}/${currentWallet?.address}`,
      state: { payload },
    })
  }

  const percentageOptions = useMemo(
    () =>
      spendPercentageOptions.map((item) => ({
        ...item,
        value: item.value * 100,
      })),
    [walletBalance],
  )

  const totalAmount = useMemo(() => {
    if (transferMode === ETransferMode.COLLECT) {
      if (isPercentageUnit) {
        return selectedWallets.reduce((acc, address) => {
          const wallet = availableWallets?.find((item) => item.address === address)
          if (!wallet) {
            return acc
          }
          const walletBalance = +((holding ? wallet.holdingBalance : wallet.balanceFormatted) || 0)
          acc += (walletBalance * +amount) / 100
          return acc
        }, 0)
      }
      return +amount * selectedWallets.length
    }

    if (isPercentageUnit) {
      return walletBalance * (+amount / 100)
    }
    return +amount
  }, [transferMode, selectedWallets, availableWallets, amount, holding, isPercentageUnit])

  const amountOptions = isPercentageUnit ? percentageOptions : spendValuesOptions

  const renderWalletsSelect = useCallback(
    () => (
      <Controller
        name="selectedWallets"
        control={control}
        render={({ field: { ref, value, onChange, ...field } }) => (
          <SelectWallet
            wallet={value}
            tokenSymbol={holding?.s}
            wallets={availableWallets?.map((wallet) => ({
              ...wallet,
              holdingData: {
                balanceFormatted: holding && wallet.holdingBalance,
              },
            }))}
            onChange={(value) => {
              onChange(value)
            }}
            isMulti={transferMode !== ETransferMode.TRANSFER}
            {...field}
          />
        )}
      />
    ),
    [transferMode, availableWallets, control],
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="body2" align="center">
          ~ {walletBalance && formatNumber(walletBalance).formatted} {holding?.s || chainCurrency}{' '}
          ($
          {formatNumber(calculateWorth(walletBalance), 2).formatted})
        </Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.content}>
        <div className={styles.content}>
          <div className={styles.inputWrapper}>
            <Controller
              name="amount"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => {
                return (
                  <InputWithRadioGroup
                    label="Amount"
                    placeholder="Enter amount to transfer"
                    isNumeric
                    className={styles.input}
                    onOptionSelect={(value) => {
                      const formattedValue = Array.isArray(value) ? value[0] : value
                      const activeRadioIndex = amountOptions.findIndex(
                        (item) => item.value === formattedValue,
                      )
                      onChange(formattedValue)
                      setActiveRadioIndex(activeRadioIndex)
                    }}
                    endAdornment={
                      <Box display="flex">
                        <Button
                          className={cn(
                            styles.toggleOption,
                            isPercentageUnit && styles.activeToggleOption,
                          )}
                          transparent
                          onClick={() => {
                            setIsPercentageUnit(!isPercentageUnit)
                            if (activeRadioIndex !== null) {
                              const newOptions = !isPercentageUnit
                                ? percentageOptions
                                : spendValuesOptions
                              onChange(newOptions[activeRadioIndex].value)
                            }
                          }}
                        >
                          %
                        </Button>
                        <AmountDropdown
                          wallets={
                            transferMode === ETransferMode.COLLECT
                              ? [
                                  ...(userWallets?.filter(
                                    (item) => item.address !== currentWallet?.address,
                                  ) || []),
                                ]
                              : currentWallet && [currentWallet]
                          }
                          onTokenSelect={(value) => {
                            setHoldingAddress(value && value.a)
                          }}
                        />
                      </Box>
                    }
                    radioGroupName="amount"
                    options={amountOptions}
                    onChange={(e) => {
                      if (!currentWallet || !currentChain.nativeTokenPriceInUsd) return
                      const newValue = convertScientificNotationNumber(
                        e.target.value,
                        MAX_TRX_DECIMALS,
                        true,
                      )
                      setActiveRadioIndex(null)
                      onChange(newValue)
                    }}
                    error={!!errors.amount?.message}
                    {...field}
                  />
                )
              }}
            />
            <Typography variant="body2">
              ${formatNumber(calculateWorth(totalAmount), 2).formatted} (
              {formatNumber(totalAmount).formatted} {holding?.s || chainCurrency})
            </Typography>
          </div>
          {transferMode === ETransferMode.TRANSFER && (
            <Tabs
              value={tab}
              onChange={(_, value) => {
                setTab(value)
              }}
              tabs={[
                {
                  label: 'External',
                  content: (
                    <div className={styles.tab}>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <OptionalInput
                            className={styles.input}
                            label="Address"
                            placeholder="Enter receiving wallet address"
                            endAdornment={<></>}
                            error={!!errors?.address?.message}
                            maxLength={addressLength}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  ),
                },
                {
                  label: 'Between your wallets',
                  content: renderWalletsSelect(),
                },
              ]}
            />
          )}
          {[ETransferMode.DISPERSE, ETransferMode.COLLECT].includes(transferMode) &&
            renderWalletsSelect()}
          {!!currentChain.features?.bribeAmount && (
            <Controller
              name="bribeAmount"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <OptionalInput
                  className={styles.input}
                  label="Bribe amount"
                  placeholder="Enter gwei amount"
                  isNumeric
                  tooltipInfo="An extra fee that you can add to increase the likelihood of your transaction being included in a block."
                  endAdornment={<PriorityCurrency />}
                  error={!!errors.bribeAmount?.message}
                  {...field}
                />
              )}
            />
          )}
          <SelectFees fees={fees} onChange={(value) => setFees(value)} />
        </div>
        <Button type="submit" disabled={isDisabled} className={styles.button} checkOnAccountLock>
          Continue
        </Button>
      </form>
    </div>
  )
}

export { TransferFromWallet }
