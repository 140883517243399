import { Dispatch, FC, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { Box, Divider } from '@mui/material'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import { updateUser } from '@/api/user'
import { ConnectWalletButton } from '@/components/header/libs/components/connect-wallet/connect-wallet-button.tsx'
import { ChainNameToNativeTokenIcon } from '@/components/header/libs/maps/chain-name-to-chain-element.tsx'
import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import {
  Button,
  ButtonIcon,
  Icon,
  InputSearch,
  Popover,
  SignOutButton,
  Switch,
  TOnChangeVisible,
  Typography,
} from '@/libs/common'
import { Logo } from '@/libs/common/logo'
import { AppMode, AppRoute, IconName, TabName } from '@/libs/enums'
import { formatNumber } from '@/libs/helper'
// import { PlanName } from '@/libs/enums'
// import { TPlan } from '@/libs/types'
import { useAppDispatch, useAppSelector } from '@/store'
import { setUser } from '@/store/slices/user.slice.ts'

import { ChainStatusInfo } from './libs/components/chain-status-info'
import { InfoItem } from './libs/components/info-item'
import { SwitchVariant } from './libs/components/switch-variant'
import { UserBlock } from './libs/components/user-block'
import styles from './styles.module.scss'

const Header: FC<{ setCollapsed: Dispatch<SetStateAction<boolean>> }> = ({ setCollapsed }) => {
  const { mode } = useParams()
  // const [searchParams] = useSearchParams()
  const selectedTokenAddress = useAppSelector((state) => state.chain.selectedTokenAddress)
  const currentChain = useAppSelector((state) => state.chain.currentChain)
  const userData = useAppSelector((state) => state.user.userData)
  const mainWallet = useAppSelector((state) => state.user.mainWallet)
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const [popoverState, setPopoverState] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })
  const navigate = useCustomNavigate()
  const { width } = useWindowDimensions()
  const isMobileSidebar = width < 768
  const isTablet = width > 480 && width < 1200
  const dispatch = useAppDispatch()

  useEffect(() => {
    localStorage.removeItem('signData')
    localStorage.removeItem('account')
    // localStorage.removeItem('signUpData')
  }, [])

  const closeMenu = (): void => {
    setPopoverState({
      visible: false,
      anchorE: undefined,
    })
  }

  const handleLogoClick = () => {
    navigate({
      isDashboard: true,
      searchParams: {
        [TabName.DASHBOARD_NAV]: 'dashboard',
        token: selectedTokenAddress || currentChain.defaultToken,
      },
      replace: true,
    })
  }

  const setConfirmation = async (state) => {
    const { data } = await updateUser({ sell_confirmation: state })
    dispatch(setUser(data.data))
  }
  const renderNavigation = () => (
    <nav className={styles.nav}>
      {/* <Link className={styles.navLink} to="/wallets">
        <div className={cn(styles.navLinkIcon, styles.navLinkIconWallet)}>
          <Icon name={IconName.WALLET} />
        </div>
        Wallets
      </Link>

      <Link className={styles.navLink} to="/settings">
        <div className={cn(styles.navLinkIcon, styles.navLinkIconSettings)}>
          <Icon name={IconName.SETTING} />
        </div>
        Settings
      </Link> */}

      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.SELECT_WALLET_TO_DEPOSIT}`}
        className={styles.buttonLink}
        onClick={closeMenu}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.DEPOSIT} />
          <span className={styles.buttonText}>Deposit</span>
        </div>
      </Link>
      {!!currentChain.features?.transfers && (
        <Link
          to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.TRANSFER}/${mainWallet?.address}`}
          className={styles.buttonLink}
          onClick={closeMenu}
        >
          <div className={styles.navLink}>
            <Icon name={IconName.TRANSFER} />
            <span className={styles.buttonText}>Transfer</span>
          </div>
        </Link>
      )}
      {!!currentChain.features?.bridge && (
        <Link
          to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.BRIDGE}`}
          className={styles.buttonLink}
          onClick={closeMenu}
        >
          <div className={styles.navLink}>
            <Icon name={IconName.BRIDGE} />
            <span className={styles.buttonText}>Bridge</span>
          </div>
        </Link>
      )}
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}?${TabName.DASHBOARD_NAV}=settings&${TabName.SETTING_NAV}=affiliates`}
        className={styles.buttonLink}
        onClick={closeMenu}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.AFFILIATE} />
          <span className={styles.buttonText}>Affiliates</span>
        </div>
      </Link>
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}/${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`}
        className={styles.buttonLink}
        onClick={closeMenu}
      >
        <div className={styles.navLink}>
          <Icon name={IconName.GOLD_WALLET} />
          <span className={styles.buttonText}>Wallets</span>
        </div>
      </Link>
      <Link
        to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}?${TabName.DASHBOARD_NAV}=settings&${TabName.SETTING_NAV}=account`}
        className={styles.buttonLink}
        onClick={closeMenu}
      >
        <div className={styles.navLink}>
          <div className={cn(styles.navLinkIcon, styles.navLinkIconWallet)}>
            <Icon name={IconName.SETTING_2} />
          </div>
          <span className={styles.buttonText}>Settings</span>
        </div>
      </Link>
      <Divider variant="fullWidth" className={styles.divider} />
      <Grid
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        onClick={() => {
          closeMenu()
          navigate({
            isDashboard: true,
            path: `${AppRoute.MODAL}/${AppRoute.ACCOUNT_LOCKING}`,
          })
        }}
      >
        <div className={cn(styles.navLink)}>
          <div className={cn(styles.navLinkIcon)}>
            <Icon name={IconName.LOCK} />
          </div>
          {userData?.locked ? 'Unlock' : 'Lock'} account
        </div>

        <Switch value={userData?.locked || false} checked={userData?.locked || false} />
      </Grid>
      <Grid
        justifyContent="space-between"
        alignItems="center"
        display="flex"
        onClick={() => {
          setConfirmation(!userData?.sell_confirmation)
        }}
      >
        <div className={cn(styles.navLink)}>
          <div className={cn(styles.navLinkIcon)}>
            <Icon name={IconName.APPROVE} />
          </div>
          Confirmation on sell account
        </div>

        <Switch
          value={userData?.sell_confirmation || false}
          checked={userData?.sell_confirmation || false}
        />
      </Grid>
      <div className={styles.navLink}>
        <SignOutButton variant="onboarding" buttonClassName={styles.signOutButton} />
      </div>
    </nav>
  )

  return (
    <>
      <Grid
        ref={ref}
        container
        component="header"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        className={cn(styles.header, styles[currentChain.chainName])}
      >
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="space-between"
          className={styles.container}
        >
          <div className={cn(styles.logoWrapper, mode ? styles[mode] : '')}>
            {mode == AppMode.LITE && (
              <Logo
                onClick={handleLogoClick}
                className={styles.logoButton}
                size="medium"
                hideText={isMobileSidebar}
              />
            )}
            {mode == AppMode.PRO && (
              <>
                {width < 1200 && (
                  <Box
                    display="flex"
                    gap={3}
                    alignItems="center"
                    className={styles.logoMenuWrapper}
                  >
                    <ButtonIcon
                      icon={IconName.SIDEBAR_COLLAPSED_BUTTON}
                      className={cn(styles.controlMobile, { [styles.open]: true })}
                      onClick={() => setCollapsed((state) => !state)}
                    />

                    {isTablet && (
                      <Logo
                        onClick={handleLogoClick}
                        className={styles.logoButton}
                        size="medium"
                        hideText={isMobileSidebar}
                      />
                    )}
                  </Box>
                )}

                {!isTablet && (
                  <Logo
                    onClick={handleLogoClick}
                    className={styles.logoButton}
                    size="medium"
                    hideText={isMobileSidebar}
                  />
                )}
              </>
            )}

            <div className={styles.search}>
              <SwitchVariant />

              {width >= 1200 && (
                <>
                  <div className={styles.searchBlock}>
                    <InputSearch placeholder="Enter token name or address" />
                  </div>
                  {userData && (
                    <Link
                      to={`${AppRoute.DASHBOARD}/${mode}/${currentChain.description.toLowerCase()}?${TabName.DASHBOARD_NAV}=settings&${TabName.SETTING_NAV}=affiliates`}
                      onClick={closeMenu}
                      className={styles.buttonLink}
                    >
                      <Button
                        className={cn(styles.affiliatesButton)}
                        styleVariant="black"
                        startIcon={<Icon name={IconName.AFFILIATE} />}
                      >
                        Affiliates
                      </Button>
                    </Link>
                  )}
                  <ChainStatusInfo />
                </>
              )}
            </div>

            {width < 1200 && <ChainStatusInfo />}

            {userData ? (
              <div
                className={styles.userData}
                onClick={(e) => {
                  const newPopoverState = {
                    visible: !popoverState.visible,
                    anchorE: e.currentTarget,
                  }
                  setPopoverState(newPopoverState)
                }}
              >
                <div>
                  <UserBlock userName={userData?.username} />

                  <InfoItem
                    title="Balance:"
                    value={
                      <Typography
                        variant="caption"
                        textColor="light-grey"
                        className={styles.balance}
                        title={mainWallet?.balanceFormatted}
                      >
                        {ChainNameToNativeTokenIcon[currentChain.chainName]}
                        {mainWallet?.balanceFormatted
                          ? formatNumber(mainWallet?.balanceFormatted).formatted
                          : 0}
                      </Typography>
                    }
                  />
                </div>

                <div className={styles.switch}>
                  <Icon name={IconName.SWITCH} />
                </div>
              </div>
            ) : (
              <ConnectWalletButton />
            )}

            {!isMobileSidebar && (
              <Popover
                className={styles.popover}
                popoverState={popoverState}
                onChangeVisible={setPopoverState}
              >
                {renderNavigation()}
              </Popover>
            )}
          </div>
        </Grid>

        {isMobileSidebar && (
          <div
            className={cn(styles.mobileSidebar, {
              [styles.visible]: popoverState.visible,
            })}
          >
            <Grid rowGap={2} display="flex" flexDirection="column">
              <ChainStatusInfo className={styles.mobileHidden} />
              <SwitchVariant />
              {renderNavigation()}
            </Grid>
          </div>
        )}
      </Grid>

      {isMobileSidebar && (
        <div
          className={cn(styles.overlay, {
            [styles.visible]: popoverState.visible,
          })}
          onClick={(e) => {
            e.stopPropagation()
            closeMenu()
          }}
        />
      )}
    </>
  )
}

export { Header }
